import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ServiceBox from "../Elements/ServiceBox";

export default function Services() {
  const serviceBoxRefs = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      serviceBoxRefs.current.forEach((box) => {
        if (box && isElementInViewport(box)) {
          box.style.opacity = 1;
          box.style.transform = "translateY(0)";
        } else if (box) {
          box.style.opacity = 0;
          box.style.transform = "translateY(50px)";
        }
      });
    };

    const isElementInViewport = (el) => {
      if (!el) return false;
      var rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Wrapper id="services">
      <div style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Awesome Services</h1><br></br>
            <p className="font13">
              Empowering success through tailored solutions. From robust software development to vigilant cybersecurity and seamless production support, we're your trusted partner for a dynamic digital journey.
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <AnimatedServiceBox ref={(el) => serviceBoxRefs.current.push(el)}>
              <Link to="/network-security">
                <ServiceBox
                  icon="network"
                  title="Network Security solution"
                  subtitle="Guarding Your Connectivity: Cutting-Edge Network Security Solutions for Unwavering Protection"
                />
              </Link>
            </AnimatedServiceBox>
            <AnimatedServiceBox ref={(el) => serviceBoxRefs.current.push(el)}>
              <Link to="/data-encryption-services">
                <ServiceBox
                  icon="data"
                  title="Data Encryption Services"
                  subtitle="Securing Tomorrow's Information: Data Encryption Services for Unrivalled Privacy and Protection"
                />
              </Link>
            </AnimatedServiceBox>
            <AnimatedServiceBox ref={(el) => serviceBoxRefs.current.push(el)}>
              <Link to="/cyber-threat-monitoring">
                <ServiceBox
                  icon="cyber"
                  title="Cyber Threat Monitoring"
                  subtitle="Shielding the Digital Frontier: Advanced Cyber Threat Protection for Unmatched Security"
                />
              </Link>
            </AnimatedServiceBox>
            <AnimatedServiceBox ref={(el) => serviceBoxRefs.current.push(el)}>
              <Link to="/marketing-services">
                <ServiceBox
                  icon="marketing"
                  title="Marketing Services"
                  subtitle="Elevate Your Brand: Strategic Marketing Solutions for Unrivalled Impact"
                />
              </Link>
            </AnimatedServiceBox>
            <AnimatedServiceBox ref={(el) => serviceBoxRefs.current.push(el)}>
              <Link to="/web-development">
                <ServiceBox
                  icon="web"
                  title="Web development"
                  subtitle="Empowering Your Online Presence: Expert Web Development for a Digital Revolution"
                />
              </Link>
            </AnimatedServiceBox>
            <AnimatedServiceBox ref={(el) => serviceBoxRefs.current.push(el)}>
              <Link to="/production-support">
                <ServiceBox
                  icon="production"
                  title="Production Support"
                  subtitle="Uninterrupted Operations, Unmatched Support: Elevate Your Production with Our Dedicated Support Services"
                />
              </Link>
            </AnimatedServiceBox>
          </ServiceBoxRow>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  background:transparent;
`;

const ServiceBoxRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 860px) {
    justify-content: center;
  }
`;

const AnimatedServiceBox = styled.div`
  width: calc(33.33% - 20px);
  margin-bottom: 20px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: Cake sans;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, opacity 0.3s ease-out;

  cursor: pointer;

  &:hover {
    transform: translateY(-5px) scale(1.1);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  opacity: 0;
  transform: translateY(50px);

  @media (max-width: 860px) {
    width: 100%;
    max-width: 400px;
    margin-right: 0;
    margin-bottom: 20px;

    &:nth-child(even) {
      margin-left: auto;
    }
  }
`;

const HeaderInfo = styled.div`
  text-align: left;
  margin-bottom: 40px;
  font-family: Cake sans;
  line-heigth:1.5;
  color:#fff;
h1{
color:#fff;}
  p {
    font-size: 18px;
    font-family: Cake sans;
    line-height:1.5;
  }
`;