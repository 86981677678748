import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Paper, FormControlLabel, Checkbox } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from './ES.png'; // Background image
import logo1 from './ethic.jpg';

const LoginPage = ({ setIsAuthenticated }) => {
  // Predefined credentials
  const predefinedUsername = 'admin';
  const predefinedPassword = 'ethic@2024';

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate(); // Initialize the navigate function

  const handleLogin = () => {
    if (username === predefinedUsername && password === predefinedPassword) {
      setIsAuthenticated(true);
      setErrorMessage('');
      navigate('/invoices-table'); // Redirect to the invoices page
    } else {
      setErrorMessage('Invalid username or password');
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh', // Full screen height
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f0f2f5',
        backgroundImage: `url(${logo1})`, // Set background image
        backgroundSize: 'cover', // Ensure the image covers the entire background
        backgroundPosition: 'center', // Center the background image
        backgroundRepeat: 'no-repeat', // Avoid repeating the image
      }}
    >
      <Paper
        elevation={4}
        sx={{
          padding: 5,
          maxWidth: 400,
          width: '100%',
          borderRadius: 3,
          backgroundColor: 'rgba(255, 255, 255, 255)', // Semi-transparent background for form
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
          <img
            src={logo}
            alt="Login"
            style={{ width: '165px', height: '100px', borderRadius: '0%' }} // Custom styles for the image
          />
        </Box>
        <Typography
          variant="h5"
          gutterBottom
          align="center"
          sx={{ fontWeight: 'bold', color: '#3f51b5' }}
        >
          Login
        </Typography>

        <TextField
          label="Username"
          variant="outlined"
          fullWidth
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#3f51b5',
              },
              '&:hover fieldset': {
                borderColor: '#303f9f',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#3f51b5',
              },
            },
          }}
        />
        <TextField
          label="Password"
          type={showPassword ? 'text' : 'password'}
          variant="outlined"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <Button
                onClick={() => setShowPassword(!showPassword)}
                sx={{ position: 'absolute', right: 10, textTransform: 'none' }}
              >
                {showPassword ? 'Hide' : 'Show'}
              </Button>
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#3f51b5',
              },
              '&:hover fieldset': {
                borderColor: '#303f9f',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#3f51b5',
              },
            },
          }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={showPassword}
              onChange={() => setShowPassword(!showPassword)}
              color="primary"
            />
          }
          label="Show Password"
          sx={{ marginTop: 1 }}
        />

        {errorMessage && (
          <Typography
            variant="body2"
            color="error"
            gutterBottom
            sx={{ marginTop: 2, textAlign: 'center' }}
          >
            {errorMessage}
          </Typography>
        )}

        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleLogin}
          sx={{
            marginTop: 3,

            borderRadius: 100,
            padding: '12px',
            backgroundColor: '#3f51b5',
           
            '&:hover': {
              backgroundColor: '#303f9f',
            },
          }}
        >
          Login
        </Button>
      </Paper>
    </Box>
  );
};

export default LoginPage;
