import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faShieldAlt, faUserShield, faKey, faMask, faShieldAlt as faShieldAltSolid, faCloudUploadAlt, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import '../styles2.css';
import bannerImage from '../../../assets/img/gif/gif2.gif';
import Background from '../../../assets/img/add/new_bg_ai.png';

const DataEncryptionServices = () => {
  return (
    <>
      <div className="banner-area">
      <img src={bannerImage} alt="Web Development Banner" className="banner-image" />

        <h2>Data Encryption Services</h2>
      </div>
      <Navbar />
      <section className='img' style={{ backgroundImage: `url(${Background})` }}>
      <div className="content">
        <h2>Secure Your Data with Advanced Encryption Solutions</h2>
        <p>In today's digital landscape, protecting sensitive information is more critical than ever. Our Data Encryption Services offer state-of-the-art encryption technologies designed to secure your data and ensure compliance with industry regulations.</p>

        <h3>Why Choose Our Data Encryption Services?</h3>
        <ul>
          <li><FontAwesomeIcon icon={faShieldAlt} /> Comprehensive Protection: Our encryption solutions safeguard your data from unauthorized access and cyber threats, whether at rest or in transit.</li>
          <li><FontAwesomeIcon icon={faUserShield} /> Regulatory Compliance: We help you meet the stringent requirements of data protection regulations such as GDPR, HIPAA, and PCI-DSS.</li>
          <li><FontAwesomeIcon icon={faKey} /> Advanced Technologies: Utilize cutting-edge encryption algorithms and protocols that ensure the highest level of security for your sensitive information.</li>
          <li><FontAwesomeIcon icon={faShieldAltSolid} /> Scalable Solutions: Our services are designed to grow with your business, providing scalable encryption solutions that meet your evolving needs.</li>
          <li><FontAwesomeIcon icon={faCheckCircle} /> Expert Support: Our team of experienced professionals is available 24/7 to provide support and ensure the continuous protection of your data.</li>
        </ul>

        <h3>Key Features of Our Data Encryption Services</h3>
        <ul>
          <li><FontAwesomeIcon icon={faLock} /> End-to-End Encryption: Protects data throughout its lifecycle, from creation to storage and transfer.</li>
          <li><FontAwesomeIcon icon={faKey} /> Encryption Key Management: Securely manage encryption keys with advanced key management solutions to ensure only authorized users have access.</li>
          <li><FontAwesomeIcon icon={faMask} /> Data Masking: Mask sensitive information in non-production environments to protect data while maintaining usability for testing and development.</li>
          <li><FontAwesomeIcon icon={faShieldAltSolid} /> Tokenization: Replace sensitive data elements with non-sensitive equivalents (tokens) to protect data while preserving its usability.</li>
          <li><FontAwesomeIcon icon={faCloudUploadAlt} /> Cloud Encryption: Secure your data in cloud environments with robust encryption solutions tailored for cloud storage and services.</li>
        </ul>

        <h3>How Our Data Encryption Services Work</h3>
        <ol>
          <li>Assessment: We conduct a thorough assessment of your current data protection measures and identify areas for improvement.</li>
          <li>Customization: Our team works with you to design a tailored encryption solution that meets your specific needs and compliance requirements.</li>
          <li>Implementation: We implement the encryption solution seamlessly, ensuring minimal disruption to your business operations.</li>
          <li>Monitoring and Support: Continuous monitoring and support to ensure the ongoing effectiveness of the encryption solution and address any issues promptly.</li>
          <li>Regular Updates: Regular updates to encryption protocols and technologies to keep your data protected against evolving threats.</li>
        </ol>

        <p>Secure your business data with our comprehensive encryption services. Contact us today to learn more about how we can help you protect your sensitive information and ensure regulatory compliance.</p>
      </div>
      </section>
      <Footer />
    </>
  );
};

export default DataEncryptionServices;
