import React from 'react';
import styled, { keyframes } from 'styled-components';
import image4 from "../../../assets/img/blogs_image/blog6.jpg"

// Keyframes for animation
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;


const slideUp = keyframes`
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

// Styled components
const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: auto;
  animation: ${fadeIn} 1s ease-in;
`;
const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 20px;
  animation: ${fadeIn} 1s ease-in;
`;

const Title = styled.h1`
  text-align: center;
  color: #7620ff;
  margin-bottom: 20px;
  animation: ${slideUp} 1s ease-out;
`;

const Section = styled.div`
  margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
  color: #7620ff;
  animation: ${slideUp} 1.2s ease-out;
`;

const Paragraph = styled.p`
  color: #4a4a4a;
  line-height: 1.6;
  animation: ${fadeIn} 1.5s ease-in;
`;

const List = styled.ul`
  color: #4a4a4a;
  padding-left: 20px;
  animation: ${fadeIn} 1.7s ease-in;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  animation: ${fadeIn} 1.9s ease-in;
`;

const Link = styled.a`
  color: #7620ff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Futuretrends = () => {
  return (
    <Container>
      <Image src={image4} alt="future trends-2024" />
      <Title>Phishing Scams</Title>
      <Paragraph>
      In the digital age, phishing scams have become one of the most prevalent and dangerous forms of cybercrime. These malicious attacks can target anyone, from individuals to large corporations, with the aim of stealing sensitive information such as passwords, credit card details, and personal identification. Understanding how phishing scams work and how to protect yourself is crucial in safeguarding your digital life.
      </Paragraph>
      
      <Section>
        <SectionTitle>What is Phishing?</SectionTitle>
        <Paragraph>
        Phishing is a cyber-attack that uses disguised email as a weapon. The attacker masquerades as a trustworthy entity, tricking the recipient into opening the email and clicking on a malicious link or attachment. This action can lead to the installation of malware, the freezing of the system as part of a ransomware attack, or the revealing of sensitive information.
        </Paragraph>
        <Paragraph>
          
        </Paragraph>
      </Section>

      <Section>
      <SectionTitle>Common Types of Phishing Scams</SectionTitle>
        <SectionTitle>Email Phishing: </SectionTitle>
        <Paragraph>
          To effectively combat cyber threats, it is essential to integrate security into every stage of the software development lifecycle. This approach, known as DevSecOps, emphasizes the importance of incorporating security practices from the initial design phase through to deployment and maintenance.
        </Paragraph>
        <SectionTitle>Spear Phishing: </SectionTitle>
        <Paragraph>
        Unlike general phishing attacks, spear phishing is targeted at specific individuals or organizations. The attacker customizes the email based on information they have gathered about the victim.
        </Paragraph>
        <SectionTitle>Whaling:  </SectionTitle>
        <Paragraph>
        This type of phishing targets high-profile individuals such as executives or high-ranking officials within an organization.
        </Paragraph>
        <SectionTitle>Vishing (Voice Phishing) </SectionTitle>
        <Paragraph>
        Attackers use phone calls to trick victims into providing personal information.
        </Paragraph>    
        <SectionTitle>Smishing (SMS Phishing</SectionTitle>
        <Paragraph>
        Similar to email phishing, this involves sending fraudulent SMS messages to lure victims into revealing sensitive information.
        </Paragraph> 
        <Paragraph>
         
        </Paragraph>
      </Section>

      <Section>
        <SectionTitle>How Phishing Scams Work</SectionTitle>
        <Paragraph>
        Phishing scams usually involve the following steps:
        </Paragraph>
        <List>
          <ListItem>
            <strong>The Hook:</strong>: The attacker sends a fake email that appears legitimate, often containing a sense of urgency or an enticing offer
          </ListItem>
          <ListItem>
            <strong>The Line:</strong>The email contains a link to a fraudulent website or an attachment that, when clicked, installs malware or prompts the user to enter personal information.
          </ListItem>
          <ListItem>
            <strong>The Sinker:</strong> Once the victim provides the information or the malware is installed, the attacker gains access to sensitive data, which can be used for financial gain or identity theft.
          </ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle>Real-World Examples of Phishing Scams</SectionTitle>
        <List>
          <ListItem>
            <strong>The Google Docs Scam: </strong> Attackers sent emails pretending to be Google Docs invitations. When recipients clicked the link, they were redirected to a fake Google login page designed to steal their credentials.
          </ListItem>
          <ListItem>
            <strong>PayPal Phishing Scam:  </strong> Cybercriminals sent emails claiming there was an issue with the recipient's PayPal account. The email contained a link to a fake PayPal login page, where victims were prompted to enter their credentials.
          </ListItem>
          <ListItem>
            <strong>Spear Phishing Attack on U.S. Political Parties:  </strong> During the 2016 U.S. elections, hackers used spear phishing emails to gain access to the email accounts of political party members.
          </ListItem>
          </List>
       
       
      </Section>
      <Section>
        <SectionTitle>How to Protect Yourself from Phishing Scams</SectionTitle>
        <List>
          <ListItem>
            <strong>Be Skeptical of Unsolicited Emails:  </strong> Always be wary of emails from unknown sources, especially those that ask for personal information or prompt immediate action.
          </ListItem>
          <ListItem>
            <strong>Verify the Sender: </strong> Check the sender's email address carefully. Phishers often use addresses that are very similar to legitimate ones but with slight variations
          </ListItem>
          <ListItem> 
            <strong>Look for Red Flags:</strong>Poor grammar, spelling mistakes, and generic greetings like "Dear Customer" are common in phishing emails.
          </ListItem>
          <ListItem>
            <strong>Hover Over Links:</strong> : Before clicking on any link, hover over it to see the actual URL. If it looks suspicious or doesn't match the sender's domain, don't click.
          </ListItem>
          <ListItem>
            <strong>Use Two-Factor Authentication (2FA):</strong> Enable 2FA on your accounts to add an extra layer of security.
          </ListItem>
          <ListItem>
            <strong>Update Your Software:</strong> Keep your software, browsers, and operating systems up to date to protect against vulnerabilities.
          </ListItem>
          <ListItem>
            <strong>Educate Yourself and Others:</strong> Stay informed about the latest phishing tactics and share this knowledge with friends, family, and colleagues.
          </ListItem>
          </List>
       
       
      </Section>

      <Section>
        <SectionTitle>Conclusion</SectionTitle>
        <Paragraph>
        Phishing scams continue to evolve, becoming more sophisticated and harder to detect. By staying vigilant, educating yourself about the signs of phishing, and taking proactive steps to protect your information, you can significantly reduce your risk of falling victim to these malicious attacks.
        </Paragraph>
        <Paragraph>
        For more information and resources on cybersecurity, visit Hive Educo. Stay safe and stay informed.
        </Paragraph>
      </Section>
    </Container>
  );
};

export default Futuretrends;