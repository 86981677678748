import React from "react";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";
import { FaFacebook, FaInstagram, FaLinkedinIn, FaWhatsapp, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import backgroundImage from '../../assets/img/add/new_bg_ai.png'
// Assets
import LogoImg from "../../assets/img/white_logo.png";

export default function Contact() {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <Wrapper>
      <Newsletter className="newsletter">
        <h4>Subscribe to our Newsletter</h4>
        <form>
          <input type="email" placeholder="Enter your email" />
          <button type="submit">Subscribe</button>
        </form>
      </Newsletter>
      <Footer className="footer">
        <FooterSections className="footer-sections">
          <FooterLogo className="footer-logo">
            <img src={LogoImg} alt="Company Logo" />
          </FooterLogo>
          <FooterQuickLinks className="footer-quick-links">
            <h4>Quick Links</h4>
            <ul>
              <li><RouterLink to="/">Home</RouterLink></li>
              <li><RouterLink to="/">About</RouterLink></li>
              <li><RouterLink to="/">Services</RouterLink></li>
              <li><RouterLink to="/careers">Careers</RouterLink></li>
              <li><RouterLink to="/blog">Blogs</RouterLink></li>
              <li><RouterLink to="/">Contact</RouterLink></li>
             
              
            </ul>
          </FooterQuickLinks>
          <FooterSupport className="footer-support">
            <h4>Support</h4>
            <ul>
              <li><RouterLink to="/terms">Terms and Conditions</RouterLink></li>
              <li><RouterLink to="/privacy">Privacy Policy</RouterLink></li>
              <li><RouterLink to="/refund">Refund Policy</RouterLink></li>
              <li><RouterLink to="/pricing">Pricing</RouterLink></li>
            </ul>
          </FooterSupport>
          <FooterSiteOnMap className="footer-site-on-map">
            <h4>Site on Map</h4>
            <p><a href="https://maps.app.goo.gl/uzZLBXEzaXLJCqQX8" target="_blank" rel="noopener noreferrer">Head Office: 23/6 Abirami Garden Nethimadu Salem-2</a></p><br></br>
            <p><a href="https://maps.app.goo.gl/SGEtHD1QJoBFjJ886" target="_blank" rel="noopener noreferrer">Office Address: ES Ethicsecur Softec pvt, ltd. 5/259a, 2nd floor, nv arcade building, near 5 roads, Salem - 636 004.</a></p>
          </FooterSiteOnMap>
        </FooterSections>
        <FooterBottom className="footer-bottom">
          <FooterBottomLeft className="footer-bottom-left">
            <p>&copy; {getCurrentYear()} ES - All Rights Reserved.</p>
          </FooterBottomLeft>
          <FooterSocial className="footer-social">
            <ul className="social-media">
              <li><a href="https://www.facebook.com/profile.php?id=61555993655328&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer"><FaFacebook /></a></li>
              <li><a href="https://www.instagram.com/official.ethicsecur?igsh=MTg2Z2ZhNDZvZGFhdQ==" target="_blank" rel="noopener noreferrer"><FaInstagram /></a></li>
              <li><a href="https://www.linkedin.com/company/es-ethicsecur-softec/" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a></li>
              <li><a href="https://wa.me/917550028487" target="_blank" rel="noopener noreferrer"><FaWhatsapp /></a></li>
              <li><a href="mailto:info@ethicsecur.com" target="_blank" rel="noopener noreferrer"><FaEnvelope /></a></li>
              <li><a href="https://maps.app.goo.gl/SGEtHD1QJoBFjJ886" target="_blank" rel="noopener noreferrer"><FaMapMarkerAlt /></a></li>
            </ul>
          </FooterSocial>
        </FooterBottom>
      </Footer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
      background-image: url('../../assets/img/add/new_bg_ai.png');

`;

const Newsletter = styled.div`
    background-image: url(${backgroundImage});
     background-size: cover;
    background-position: center;
 
  padding: 20px 0;
  font-family: Cake sans;
  text-align: center;

  h4 {
    margin-bottom: 10px;
    font-family: Cake sans;
    font-size: 24px;
     font-weight:lighter;
     color:#fff;
  }

  form {
    display: inline;
    justify-content: center;
    align-items: center;
      
    input {
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-right: 10px;
      width: 250px;
    }

    button {
      padding: 10px 20px;
      background-color: #7620ff;
      color: #fff;
      border: none;
      
      font-family: Cake sans;
      border-radius: 4px;
    
      cursor: pointer;
    }
  }

  @media (max-width: 768px) {
    form {
      flex-direction: column;
         display:flex;

      input {
        margin-right: 0;
        margin-bottom: 10px;
        width: 50%;
      }

      button {
        width: 25%;
      }
    }
  }
`;

const Footer = styled.footer`
  background-color: #000000;
  color: #fff;
  padding: 40px 20px;
  text-align: left;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const FooterSections = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FooterLogo = styled.div`
  flex: 1;
  min-width: 200px;
  padding: 10px;

  img {
    width: 300px;
    height: 300px;
    margin-top: -60px;

    @media (max-width: 768px) {
      width: 250px;
      height: 250px;
    }

      @media (max-width: 1200px) {
      width: 100%;
      height: 250px;
    }
  }

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const FooterQuickLinks = styled.div`
  flex: 1;
  min-width: 200px;
  padding: 10px;

  h4 {
    font-size: 20px;
    margin-bottom: 10px;
    font-family: Cake sans;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 5px;
    }

    a {
      color: #fff;
      text-decoration: none;
      cursor: pointer;
      font-family: Cake sans;

      &:hover {
        text-decoration: underline;
          color:#800080;
      }
    }
  }

  @media (max-width: 768px) {
    text-align: center;
    padding: 20px 0;

    ul {
      padding: 0;
    }

    li {
      margin-bottom: 10px;
    }
  }
`;

const FooterSupport = styled.div`
  flex: 1;
  min-width: 200px;
  padding: 10px;

  h4 {
    font-size: 20px;
    font-family: Cake sans;
    margin-bottom: 10px;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 5px;
    }

    a {
      color: #fff;
      text-decoration: none;
      cursor: pointer;
font-family: Cake sans;

      &:hover {
        text-decoration: underline;
          color:#800080;
      }
    }
  }

  @media (max-width: 768px) {
    text-align: center;
    padding: 20px 0;

    ul {
      padding: 0;
    }

    li {
      margin-bottom: 10px;
    }
  }
`;

const FooterSiteOnMap = styled.div`
  flex: 1;
  min-width: 200px;
  padding: 10px;

  h4 {
    font-size: 20px;
    margin-bottom: 10px;
    font-family: Cake sans;
  }

  p {
    margin: 0 0 10px 0;
  }

  a {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    font-family: Cake sans;

    &:hover {
      text-decoration: underline;
        color:#800080;
    }
  }

  @media (max-width: 768px) {
    text-align: center;
    padding: 20px 0;

    p {
      margin-bottom: 10px;
    }
  }
`;

const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-top: 1px solid #444;
  padding-top: 20px;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const FooterBottomLeft = styled.div`
  display: flex;
  align-items: center;
font-family: Cake sans;
  img {
    width: 50px;
    margin-right: 10px;

    @media (max-width: 768px) {
      width: 40px;
      margin-right: 5px;
    }
  }

  p {
    margin: 0;
    color: #fff;
  }

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const FooterSocial = styled.div`
  flex: 1;
  text-align: right;

  .social-media {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: flex-end;

    li {
      margin-right: 30px;
      font-size: 20px;
    }

    a {
      color: #fff;
      text-decoration: none;
      transition: color 0.3s ease;

      &:hover {
       
          color:#800080;
      }
    }
  }
`;