import React, { useState } from 'react';
import styled from 'styled-components';
import AnimatedBackground from './AnimatedBackground';
import Navbar from '../Navbar';
import Footer from '../Footer2';


export default function Contact() {
  const [result, setResult] = useState("");
  const [selectedRole, setSelectedRole] = useState("Select an option");
  const [showMenu, setShowMenu] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "99f4d976-1b1c-4a54-a450-2553963e8b09");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
      setSelectedRole("Select an option");
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  const handleSelectChange = (value) => {
    setSelectedRole(value);
    setShowMenu(false);
  };

  return (
    <>
    <Navbar />
      <AnimatedBackground />
      <FormWrapper>
        <h1>Find Your Dream Job</h1>
        <form onSubmit={onSubmit}>
          <FormGroup>
            {/* <InputLabel htmlFor="fname">Full Name</InputLabel> */}
            <InputField type="text" id="fname" name="fname"  placeholder='Full Name'/>
          </FormGroup>
          <FormGroup>
            {/* <InputLabel htmlFor="number">Phone Number</InputLabel> */}
            <InputField type="tel" id="number" name="number" placeholder='Phone Number'/>
          </FormGroup>
          <FormGroup>
            {/* <InputLabel htmlFor="email">Email</InputLabel> */}
            <InputField type="email" id="email" name="email" placeholder='Enter Your Email'/>
          </FormGroup>
          <FormGroup>
            <InputLabel htmlFor="role">What role are you looking for?</InputLabel>
            <CustomSelect onClick={() => setShowMenu(!showMenu)}>
              <SelectTrigger>{selectedRole}</SelectTrigger>
              {showMenu && (
                <SelectMenu>
                  <SelectItem onClick={() => handleSelectChange("Cyber Security")}>Cyber Security</SelectItem>
                  <SelectItem onClick={() => handleSelectChange("Front End Developer")}>Front End Developer</SelectItem>
                  <SelectItem onClick={() => handleSelectChange("Full Stack Developer")}>Full Stack Developer</SelectItem>
                  <SelectItem onClick={() => handleSelectChange("Marketing Services")}>Marketing Services</SelectItem>
                </SelectMenu>
              )}
            </CustomSelect>
          </FormGroup>
          <FormGroup>
            <InputLabel htmlFor="file">Upload Your Resume</InputLabel>
            <FileInput type="file" id="file" name="file" className='upload-box' />
          </FormGroup>
          <SubmitButton type="submit">Send Message</SubmitButton>
        </form>
        <ResultMessage>{result}</ResultMessage>
      </FormWrapper>
      <Footer />
    </>
  );
}

const FormWrapper = styled.div`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 100%;
  max-width: 600px;
  padding: 30px;
  padding-bottom:20px;
  background: none; /* Slightly opaque background for better readability */
 font-family: 'Cake', sans;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3); /* Slight shadow to highlight the form */
  text-align: left;

  h1 {
    font-size: 24px;
    color: #fff;
    margin-bottom: 20px;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const InputLabel = styled.label`
  font-size: 16px;
  color: #fff;
 font-family: 'Cake', sans;
  font-weight: 700; /* Bold font weight */
  display: block;
  margin-bottom: 8px;
`;

const InputField = styled.input`

border: none;
outline:none;
background:none;
font-size: 16px;
 color: #555;
 padding: 20px 10px 20px 5px;
 letter-spacing:1px;
 marigin-bottom:20px;
 border-bottom:2px solid #777;
 transition :.5s ease;
  width: 100%;
 &:hover{
 border-bottom:3px solid white;
 }

`;

const FileInput = styled.input.attrs({ type: 'file' })`
font-size: 16px;
  background: none;
  color:white;
  border-radius: 50px;

  width: 300px;
  height: 50px; /* Adjust height */
  padding: 10px; /* Adjust padding */
  outline: none;
  border: none; /* Ensures no border around the input */

  &::-webkit-file-upload-button {
    color: white;
    background: #7620ff;
    padding: 10px 20px; /* Adjust padding */
    border-radius: 50px;
    // box-shadow: 1px 0 1px 1px #6b4559;
    border: none; /* Ensures no border around the button */
    cursor: pointer; /* Ensures pointer cursor on hover */
    outline: none;
    font-size: 16px;
  }

  &::-webkit-file-upload-button:hover {
    background: #580cd2;
  }
`;

const CustomSelect = styled.div`
  position: relative;
  width: 100%;
  background: none; /* Semi-transparent background */
  border: none;
  // outline:none;

  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SelectTrigger = styled.div`
  font-size: 16px;
  color: #fff;
  font-family: 'Cake', sans-serif;/* Applying Caveat font */
  font-weight: 700;
`;

const SelectMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid rgba(255, 255, 255, 0.6);
  z-index: 2;
`;

const SelectItem = styled.div`
  padding: 10px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
 font-family: 'Cake', sans-serif;
  font-weight: 700;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const SubmitButton = styled.button`
  width: 70%;
  margin-left:80px;
  background-color: #7620ff;
  color: #fff;
  padding: 15px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #580cd2;
  }
`;

const ResultMessage = styled.span`
  display: block;
  margin-top: 15px;
  font-size: 16px;
  color: #fff;
`;