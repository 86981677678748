import React from 'react';
import styled from 'styled-components';
import Bg from '../../assets/img/about1.avif'; // Ensure the image path is correct

const AboutUs = () => {
  return (
    <Wrapper id='about'>
      <Container>
        <HeaderInfo className="font40 extraBold">About Us</HeaderInfo>
        <AboutContent className="font13">
          We are a software development and data security company based in Tamil Nadu, India. With a focus on cyber security, we strive to provide innovative solutions to protect businesses from evolving threats in the digital world.
          <br /><br />
          Our team of experts is dedicated to developing cutting-edge software solutions that ensure the confidentiality, integrity, and availability of data. We understand the critical importance of data security in today's interconnected world and work tirelessly to safeguard our clients' valuable information.
        </AboutContent>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  margin-top: 0px;
  min-height: 50vh; /* Ensures the section takes up at least the full viewport height */
  color: #000;
  background-color: #ffffff;
  background-image: url(${Bg}); /* Apply the background image here */
  background-size: cover; /* Adjusts the background image to cover the entire section */
  background-position: center; /* Centers the background image */
  background-repeat: no-repeat; /* Ensures the background image does not repeat */

  @media (max-width: 768px) {
    min-height: 40vh; /* Adjust the minimum height for smaller screens */
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 15px; /* Added padding for better visibility */
  background-color: transparent; /* Optional: Adds a slight white overlay to improve text readability */

  @media (max-width: 768px) {
    padding: 20px 10px; /* Adjust the padding for smaller screens */
  }
`;

const HeaderInfo = styled.div`
  text-align: left;
  font-family: 'Cake sans', sans;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    text-align: center; /* Center text for smaller screens */
    margin-bottom: 20px; /* Adjust margin for smaller screens */
  }
`;

const AboutContent = styled.p`
  font-size: 18px;
  font-family: 'Cake sans', sans;
  line-height: 1.6;
  text-align: justify; /* Justify text alignment for larger screens */
  color: #000;
  margin: 0; /* Remove default margin to prevent extra spacing */

  @media (max-width: 768px) {
    font-size: 16px; /* Adjust font size for smaller screens */
    text-align: left; /* Left-align text for smaller screens */
    margin-right:px;
  }
    @media (max-width: 1500px) {
    font-size: 16px; /* Adjust font size for smaller screens */
    text-align: left; /* Left-align text for smaller screens */
    margin-right:50%;
    text-align:justify;
  }
`;

export default AboutUs;
