import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Button,
  TextField
} from '@mui/material';
import { useNavigate } from 'react-router-dom'; // For routing to Create Invoice page
import { FiTrash2, FiEye } from 'react-icons/fi';

export default function InvoiceTablePage() {
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [filterText, setFilterText] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const navigate = useNavigate();

  // Fetch invoices from localStorage on component mount
  useEffect(() => {
    const storedInvoices = JSON.parse(localStorage.getItem('invoices') || '[]');
    setInvoices(storedInvoices);
    setFilteredInvoices(storedInvoices); // Initially show all invoices
  }, []);

  // Handle navigation to Create Invoice page
  const handleCreateInvoice = () => {
    navigate('/create-invoice');
  };

  // Handle Delete Invoice
  const handleDeleteInvoice = (id) => {
    const updatedInvoices = invoices.filter((invoice) => invoice.id !== id);
    setInvoices(updatedInvoices);
    setFilteredInvoices(updatedInvoices);
    localStorage.setItem('invoices', JSON.stringify(updatedInvoices));
  };

  // Handle View Invoice Details
  const handleViewInvoice = (id) => {
    navigate(`/invoice/${id}`);
  };

  // Filter by Search Text
  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setFilterText(searchTerm);
    const filtered = invoices.filter((invoice) =>
      invoice.client.toLowerCase().includes(searchTerm)
    );
    setFilteredInvoices(filtered);
  };

  // Sort Invoices Alphabetically by Customer Name
  const handleSort = () => {
    const sortedInvoices = [...filteredInvoices].sort((a, b) =>
      sortOrder === 'asc'
        ? a.client.localeCompare(b.client)
        : b.client.localeCompare(a.client)
    );
    setFilteredInvoices(sortedInvoices);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  return (
    <Box sx={{ padding: '24px', backgroundColor: '#f4f6f8', minHeight: '100vh' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#333' }}>Invoice Table</Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ backgroundColor: '#1976d2', padding: '8px 16px' }}
          onClick={handleCreateInvoice}
        >
          Create Invoice
        </Button>
      </Box>

      {/* Filter and Search Section */}
      <Box sx={{ marginBottom: 2, display: 'flex', gap: 2 }}>
        <TextField
          label="Search by Customer"
          variant="outlined"
          value={filterText}
          onChange={handleSearch}
          sx={{ flex: 1 }}
        />
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#1976d2',
            color: '#fff',
            padding: '8px 16px',
            '&:hover': { backgroundColor: '#1565c0' }
          }}
          onClick={handleSort}
        >
          Sort {sortOrder === 'asc' ? 'A-Z' : 'Z-A'}
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
        <Table>
          <TableHead sx={{ backgroundColor: '#1976d2' }}>
            <TableRow>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Customer Name</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Phone Number</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Invoice ID</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Date</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredInvoices.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No invoices found
                </TableCell>
              </TableRow>
            ) : (
              filteredInvoices.map((invoice, index) => (
                <TableRow key={index} sx={{ '&:hover': { backgroundColor: '#f1f3f5' } }}>
                  <TableCell>{invoice.client}</TableCell>
                  <TableCell>{invoice.phoneNumber}</TableCell>
                  <TableCell>{invoice.id}</TableCell>
                  <TableCell>{invoice.date}</TableCell>
                  <TableCell>
                    <Button
                      sx={{ color: '#1565c0', marginRight: 1 }}
                      onClick={() => handleViewInvoice(invoice.id)}
                    >
                      <FiEye /> View
                    </Button>
                    <Button
                      color="error"
                      sx={{ color: '#d32f2f' }}
                      onClick={() => handleDeleteInvoice(invoice.id)}
                    >
                      <FiTrash2 /> Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
