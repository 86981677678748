import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, faBullseye, faPalette, faEdit, faUsers, faLaptop, faChartLine, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import '../styles2.css';
import bannerImage from '../../../assets/img/gif/digital.gif';
import Background from '../../../assets/img/add/new_bg_ai.png';

const MarketingServices = () => {
  return (
    <>
      <div className="banner-area">
      <img src={bannerImage} alt="Web Development Banner" className="banner-image" />

        <h2>Marketing Services</h2>
      </div>
      <Navbar />
      <section className='img' style={{ backgroundImage: `url(${Background})` }}>
      <div className="content">
        <h2>In today's competitive marketplace, having a robust marketing strategy is essential to stand out and achieve your business goals.</h2>
        <p>Our comprehensive marketing services are designed to elevate your brand, reach your target audience, and drive meaningful engagement and conversions. From strategic planning to execution, we offer tailored solutions to meet your unique needs and ensure your business thrives.</p>

        <h3>Why Choose Our Marketing Services?</h3>
        <ul>
          <li><FontAwesomeIcon icon={faLightbulb} /> Strategic Planning and Consulting: Develop a strategic marketing plan aligned with your business objectives through market research and competitor analysis.</li>
          <li><FontAwesomeIcon icon={faBullseye} /> Digital Marketing: Utilize SEO, PPC advertising, social media marketing, email marketing, and content marketing to effectively reach your audience and generate leads.</li>
          <li><FontAwesomeIcon icon={faPalette} /> Brand Development: Create a strong brand identity with logo design, brand messaging, and visual identity that resonates with your audience.</li>
          <li><FontAwesomeIcon icon={faEdit} /> Content Creation and Marketing: Engage your audience with compelling content including blog posts, articles, videos, and infographics.</li>
          <li><FontAwesomeIcon icon={faUsers} /> Social Media Management: Enhance your social media presence with strategy development, profile management, and performance analysis.</li>
          <li><FontAwesomeIcon icon={faLaptop} /> Website Design and Development: Build a user-friendly website optimized for performance, SEO, and user experience.</li>
          <li><FontAwesomeIcon icon={faChartLine} /> Analytics and Reporting: Measure marketing success with KPI tracking, data analysis, and actionable insights.</li>
          <li><FontAwesomeIcon icon={faNewspaper} /> Public Relations (PR): Improve brand visibility with press releases, media relations, and event coordination.</li>
        </ul>

        <h3>Benefits of Our Marketing Services</h3>
        <ul>
          <li>Increased Brand Awareness: Boost visibility and recognition in the market.</li>
          <li>Targeted Campaigns: Reach the right audience with tailored marketing strategies.</li>
          <li>Higher Engagement: Foster meaningful interactions with your audience.</li>
          <li>Lead Generation: Drive quality leads that convert into customers.</li>
          <li>Data-Driven Insights: Make informed decisions with detailed analytics and reporting.</li>
          <li>Consistent Growth: Achieve sustainable business growth with effective marketing.</li>
        </ul>

        <p>Don’t let your business go unnoticed. Contact us today to learn more about how our customized marketing services can help you achieve your goals, enhance your brand, and drive success in today's competitive landscape.</p>
      </div>
      </section>
      <Footer />
    </>
  );
};

export default MarketingServices;
