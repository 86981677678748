import React from "react";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";
import CloseIcon from "../../assets/svg/CloseIcon";
import LogoImg from "../../assets/svg/3d-logo-triangle-2-410x453.webp";

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  return (
    <Wrapper className="animate darkBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          <Logo src={LogoImg} alt="EthicSecur SofTec Logo" />
          <h1 className="whiteColor font20" style={{ marginLeft: "15px" }}>
            ES EthicSecur SofTec
          </h1>
        </div>
        <CloseBtn onClick={() => toggleSidebar(!sidebarOpen)} className="animate pointer">
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      <UlStyle className="flexNullCenter flexColumn">
        <li className="semiBold font15 pointer">
          <RouterLink
            to="/"
            onClick={() => toggleSidebar(!sidebarOpen)}
            className="whiteColor"
            style={{ padding: "10px 15px", textDecoration: "none" }}
          >
            Home
          </RouterLink>
        </li>
        <li className="semiBold font15 pointer">
          <RouterLink
            to="/services"
            onClick={() => toggleSidebar(!sidebarOpen)}
            className="whiteColor"
            style={{ padding: "10px 15px", textDecoration: "none" }}
          >
            Services
          </RouterLink>
        </li>
        <li className="semiBold font15 pointer">
          <RouterLink
            to="/blog"
            onClick={() => toggleSidebar(!sidebarOpen)}
            className="whiteColor"
            style={{ padding: "10px 15px", textDecoration: "none" }}
          >
            Blog
          </RouterLink>
        </li>
        <li className="semiBold font15 pointer">
          <RouterLink
            to="/pricing"
            onClick={() => toggleSidebar(!sidebarOpen)}
            className="whiteColor"
            style={{ padding: "10px 15px", textDecoration: "none" }}
          >
            Pricing
          </RouterLink>
        </li>
        <li className="semiBold font15 pointer">
          <RouterLink
            to="/careers"
            onClick={() => toggleSidebar(!sidebarOpen)}
            className="whiteColor"
            style={{ padding: "10px 15px", textDecoration: "none" }}
          >
            Careers
          </RouterLink>
        </li>
        <li className="semiBold font15 pointer">
          <RouterLink
            to="/contact"
            onClick={() => toggleSidebar(!sidebarOpen)}
            className="whiteColor"
            style={{ padding: "10px 15px", textDecoration: "none" }}
          >
            Contact
          </RouterLink>
        </li>
      </UlStyle>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: ${(props) => (props.sidebarOpen ? "0" : "-100%")};
  background-color: #333; /* Example background color */
  z-index: 9999;
  transition: right 0.3s ease-in-out;
  
  @media (max-width: 768px) {
    width: 100%;
    padding: 0 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    right: ${(props) => (props.sidebarOpen ? "0px" : "-100%")};
  }
`;

const SidebarHeader = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img`
  width: 50px;
  height: auto;
  margin-right: 15px;
`;

const CloseBtn = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  padding: 10px;
  cursor: pointer;
`;

const UlStyle = styled.ul`
  padding: 20px;
  margin-top: 20px;
  list-style-type: none;
  li {
    margin-bottom: 15px;
  }
`;