import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faTools, faChartLine, faShieldAlt, faUserCog, faFileAlt, faCogs } from '@fortawesome/free-solid-svg-icons';
import '../styles2.css';
import bannerImage from '../../../assets/img/gif/production.gif';
import Background from '../../../assets/img/add/new_bg_ai.png';

const ProductionSupport = () => {
  return (
    <>
      <div className="banner-area">
      <img src={bannerImage} alt="Web Development Banner" className="banner-image" />

        <h2>Production Support</h2>
      </div>
      <Navbar />
      <section className='img' style={{ backgroundImage: `url(${Background})` }}>
      <div className="content">
        <h2>Ensuring smooth operations and minimal downtime is crucial for businesses relying on IT systems and applications.</h2>
        <p>Our production support services are designed to maintain the stability, reliability, and performance of your systems, allowing you to focus on core business activities without interruption. Whether you need proactive monitoring, rapid issue resolution, or ongoing maintenance, our dedicated team is here to support your production environment effectively.</p>

        <h3>Why Choose Our Production Support Services?</h3>
        <ul>
          <li><FontAwesomeIcon icon={faClock} /> 24/7 Monitoring and Incident Management: Round-the-clock monitoring to detect and address issues promptly.</li>
          <li><FontAwesomeIcon icon={faTools} /> Rapid Response and Troubleshooting: Swift incident response and problem resolution to minimize business impact.</li>
          <li><FontAwesomeIcon icon={faChartLine} /> Performance Optimization: Regular monitoring, tuning, and maintenance for optimal system efficiency.</li>
          <li><FontAwesomeIcon icon={faShieldAlt} /> Patch Management and Updates: Secure and up-to-date systems with managed software updates and security patches.</li>
          <li><FontAwesomeIcon icon={faUserCog} /> Capacity Planning and Scalability: Forecasting resource needs and recommending enhancements for future scalability.</li>
          <li><FontAwesomeIcon icon={faFileAlt} /> Incident Reporting and Analysis: Detailed insights and recommendations for system stability and improvement.</li>
          <li><FontAwesomeIcon icon={faCogs} /> Documentation and Knowledge Management: Comprehensive documentation and knowledge transfer for streamlined operations.</li>
          <li><FontAwesomeIcon icon={faChartLine} /> Continuous Improvement: Refinement of support processes for enhanced efficiency and reliability.</li>
        </ul>

        <h3>Benefits of Our Production Support Services</h3>
        <ul>
          <li>Minimized Downtime: Ensure uninterrupted operations and minimize technical issue impacts.</li>
          <li>Enhanced Reliability: Maintain system performance and reliability.</li>
          <li>Improved Security: Proactive security measures and timely updates for protection.</li>
          <li>Scalability: Scale infrastructure and resources to support business growth.</li>
          <li>Cost Efficiency: Optimize IT operations and resource utilization to reduce costs.</li>
          <li>Expertise and Experience: Benefit from our team’s expertise in managing complex production environments.</li>
        </ul>

        <p>Focus on your core business activities while we ensure the smooth operation of your production environment. Contact us today to learn more about how our production support services can support your business objectives and drive operational excellence.</p>
      </div>
      </section>
      <Footer />
    </>
  );
};

export default ProductionSupport;
