import React from 'react';
import Navbar from '../pages/Navbar';
import Footer from '../pages/Footer2';
import '../pages/style.css';

const Privacy = () => {
    return (
        <>
            <div className="banner-area1" id="privacy">
                <h2>Privacy Policy</h2>
            </div>
            <Navbar />
            <section id="header" className='image'>
                {/* <h1 className="title">Privacy Policy</h1> */}<br></br>
                <div className="container center" id="privacy">
                    <div className="row mt-5 justify-center">
                        <div className="col-12">
                            <div className="content">
                                    <h2>Privacy Policy for ES EthicSecur SofTec PVT LTD</h2>
                                    <p><strong>Effective Year:</strong> 2023</p>
                                    <ol>
                                        <li>
                                            <h3>Introduction</h3>
                                            <p>ES EthicSecur SofTec PVT LTD is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services.</p>
                                        </li>
                                        <li>
                                            <h3>Information We Collect</h3>
                                            <p>We may collect and process the following data:</p>
                                            <ul>
                                                <li><strong>Personal Information:</strong> Name, email address, phone number, postal address, and other contact details.</li>
                                                <li><strong>Usage Data:</strong> Information about how you use our website and services, including your IP address, browser type, access times, and pages viewed.</li>
                                                <li><strong>Transaction Data:</strong> Details about payments to and from you and other details of products and services you have purchased from us.</li>
                                                <li><strong>Technical Data:</strong> Information about your device(s), internet connection, and usage details collected through cookies and other tracking technologies.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>How We Use Your Information</h3>
                                            <p>We use the information we collect for the following purposes:</p>
                                            <ul>
                                                <li>To Provide Services: To deliver and manage our services, process payments, and provide customer support.</li>
                                                <li>To Improve Our Services: To understand how users interact with our services, enhance user experience, and develop new features.</li>
                                                <li>To Communicate: To send you updates, newsletters, marketing communications, and other information that may be of interest to you.</li>
                                                <li>To Ensure Security: To protect our users, detect and prevent fraud, and comply with legal obligations.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Sharing Your Information</h3>
                                            <p>We do not sell or rent your personal information to third parties. We may share your information with:</p>
                                            <ul>
                                                <li>Service Providers: Third-party vendors who provide services on our behalf, such as payment processing, data analysis, and email delivery.</li>
                                                <li>Legal Obligations: When required by law or to respond to legal processes, such as a court order or subpoena.</li>
                                                <li>Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Data Security</h3>
                                            <p>We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>
                                        </li>
                                        <li>
                                            <h3>Your Rights</h3>
                                            <p>Depending on your location, you may have the following rights regarding your personal information:</p>
                                            <ul>
                                                <li>Access: The right to request access to the personal information we hold about you.</li>
                                                <li>Correction: The right to request correction of inaccurate or incomplete personal information.</li>
                                                <li>Deletion: The right to request the deletion of your personal information.</li>
                                                <li>Objection: The right to object to the processing of your personal information for certain purposes.</li>
                                                <li>Portability: The right to request the transfer of your personal information to another organization.</li>
                                            </ul>
                                            <p>To exercise these rights, please contact us at <a href="mailto:info@ethicsecur.com">info@ethicsecur.com</a>.</p>
                                        </li>
                                        <li>
                                            <h3>Cookies and Tracking Technologies</h3>
                                            <p>We use cookies and similar tracking technologies to collect and use personal information about you. You can manage your cookie preferences through your browser settings.</p>
                                        </li>
                                        <li>
                                            <h3>Third-Party Links</h3>
                                            <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices or the content of such websites. We encourage you to read the privacy policies of any third-party websites you visit.</p>
                                        </li>
                                        <li>
                                            <h3>Changes to This Privacy Policy</h3>
                                            <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any changes.</p>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
               
            </section>
            <Footer />
        </>
    );
};

export default Privacy;