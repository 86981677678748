import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom"; // For routing
import Sidebar from "../pages/SideBar";
import Backdrop from "../Elements/Backdrop";

// Assets
import Logo from "../../assets/img/white_logo.png";
import BurgerIcon from "../../assets/svg/BurgerIcon";

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "60px" } : { height: "80px" }}>
        <NavInner className="container flexSpaceCenter">
          <RouterLink className="pointer flexNullCenter" to="/">
          <LogoImage src={Logo} alt="EthicSecur" />
            <h1 style={{ marginLeft: "15px" }} className="font20 extraBold">
             
            </h1>
          </RouterLink>
          <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            <li className="semiBold font15 pointer">
              <RouterLink activeClass="active" style={{ padding: "10px 15px" }} to="/">
                Home
              </RouterLink>
            </li>
            <li className="semiBold font15 pointer">
              <RouterLink activeClass="active" style={{ padding: "10px 15px" }} to="/">
                About
              </RouterLink>
            </li>
            <li className="semiBold font15 pointer">
              <RouterLink activeClass="active" style={{ padding: "10px 15px" }} to="/">
                Services
              </RouterLink>
            </li>
            {/* <li className="semiBold font15 pointer">
              <RouterLink activeClass="active" style={{ padding: "10px 15px" }} to="/">
                Projects
              </RouterLink>
            </li> */}
            <li className="semiBold font15 pointer">
              <RouterLink activeClass="active" style={{ padding: "10px 15px" }} to="/blog">
                Blog
              </RouterLink>
            </li>
            <li className="semiBold font15 pointer">
              <RouterLink activeClass="active" style={{ padding: "10px 15px" }} to="/pricing">
                Pricing
              </RouterLink>
            </li>
            <li className="semiBold font15 pointer">
              <RouterLink activeClass="active" style={{ padding: "10px 15px" }} to="/careers">
                Careers
              </RouterLink>
            </li>
            <li className="semiBold font15 pointer">
              <RouterLink activeClass="active" style={{ padding: "10px 15px" }} to="/">
                Contact
              </RouterLink>
            </li>
          </UlWrapper>
          {/* <UlWrapperRight className="flexNullCenter">
            <li className="semiBold font15 pointer flexCenter">
              <a href="https://ethicsecur.com/" className="radius8 lightBg" style={{ padding: "10px 15px" }}>
                Login
              </a>
            </li>
          </UlWrapperRight> */}
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;

  position: fixed;
  top: 2.92%;
  left: 0;
  z-index: 999;
  background-color: ${props => (props.scrolled ? 'transparent' : '#262166')}; // Adjust color on scroll
  height: ${props => (props.scrolled ? '60px' : '-50px')};
  transition: background-color 0.5s ease, height 2s ease;
  box-shadow: ${props => (props.scrolled ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none')}; // Box shadow on scroll background-image: radial-gradient( circle farthest-corner at 3.2% 49.6%,  rgba(80,12,139,0.87) 0%, rgba(161,10,144,0.72) 83.6% );
`;


const LogoImage = styled.img`
margin-top:17px;
 width: 35%;
  height:100%;
   @media (max-width: 1200px) {
 
    width:40%;
   
  }

  @media (max-width: 1100px) {
 
    width:70%;
  
  }
 @media (max-width: 992px) {
    width: 100%;
    margin-top:10px;

  }
  @media (max-width: 912px) {
    width: 300%;
    margin-top:10px;

  }

  @media (max-width: 900px) {
    width: 300%;
    margin-top:17px;

  }
    @media (max-width: 830px) {
    width: 800%;
    margin-top:17px;

  }
 
 @media (max-width: 760px) {
    width: 20%;
    margin-top:20px;
 
  }

    @media (max-width: 500px) {
 
    width:30%;
   
  }
    @media (max-width: 480px) {
    width: 35%;
    margin-top:20px;
  }
     @media (max-width: 360px) {
    width: 50%;
   
 
    
  }
`;

const NavInner = styled.div`
position: relative;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right:0px;
  padding: 0 2rem; // Add some padding
`;

const BurderWrapper= styled.div`
  display: none; 
  @media (max-width: 768px) {
    display: block; 
    position: absolute;
    top: 45px; 
    right: 20px; 
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 10; 
  }

  div {
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 5px 0;
    transition: 0.4s;
  }

  /* Change the icon appearance when the menu is open */
  &.open div {
    background-color: #fff;
  }

  &.open div:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  &.open div:nth-child(2) {
    opacity: 0;
  }

  &.open div:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }
`;

const UlWrapper = styled.ul`
  display: flex;
  margin-left:150px;
  margin-top:20px;
  @media (max-width: 760px) {
    display: none;
  }

  li {
    margin-right: 15px;
    #a861a1

    &:last-child {
      margin-right: 0;
    }

    a {
      display: block;
      padding: 10px 15px;
      font-size: 15px;
      font-weight: 600;
     color: white;
      text-decoration: none;
      transition: all 0.3s ease;
      cursor: pointer;

      &:hover {
        color: #007bff;
      }

      &.active {
        color: #a861a1;
      }
    }
  }
`;
