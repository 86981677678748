import React from "react";
import styled from "styled-components";
// Components
import TestimonialBox from "../Elements/TestimonialBox";

export default function TestimonialSlider() {
  return (
    <div>
      <Wrapper>
      <HeaderInfo>
          <h1 className="font40 extraBold">Why Us?</h1>
          <p>At ES EthicSecur SofTec, we combine cutting-edge technology with expert knowledge to provide unmatched cyber security solutions. Trust us to protect your digital assets with our comprehensive, proactive, and customized services.</p>
        </HeaderInfo><br></br>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            author="Expert Team"
            text="Our team comprises cybersecurity experts with a deep understanding of industry best practices, guaranteeing a comprehensive and effective security strategy."
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Cyber threats don't sleep, and neither do we. Our round-the-clock monitoring and support ensure that your systems are protected at all times."
            author="24/7 Vigilance"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="We understand that one size doesn't fit all. Our customised cybersecurity solutions are designed to meet the unique needs and challenges of your business."
            author="Tailored Solutions"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="We employ the latest technologies to safeguard your digital assets, ensuring robust defense against evolving cyber threats."
            author="Cutting-Edge Protection"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Our proactive monitoring and threat intelligence keep you ahead of potential risks, minimizing vulnerabilities and providing a secure digital environment."
            author="Proactive Approach"
          />
        </LogoWrapper>
       
      </Wrapper>
    </div>
  );
}
const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  width: 100%;
  padding: 50px 20px;
`;

const HeaderInfo = styled.div`
  flex: 1 100%; /* Make HeaderInfo take full width on its own line */
    text-align: left;
  margin-bottom: 40px;
  font-family: Cake sans;
  line-heigth:1.5;
  color:#000;
margin-left:90px;

p{
font-size: 18px;
line-height:1.5;
}
  @media (max-width: 768px) {
    text-align: center; /* Center text for smaller screens */
    margin-bottom: 20px; /* Adjust margin for smaller screens */
    // margin-right:60px;
    text-align:justify;
    margin-left:-10px;
  }
`;

const LogoWrapper = styled.div`
  flex: 1 1 calc(33.333% - 40px); /* Adjust to fit 3 items per row with gap */
  max-width: calc(33.333% - 40px);
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;

  :focus-visible {
    outline: none;
    border: 0px;
  }

  @media (max-width: 860px) {
    flex: 1 1 100%; /* Full width on smaller screens */
    max-width: 100%;
  }
`;