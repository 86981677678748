import React, { useState, useEffect } from 'react';
import { FaArrowCircleUp } from 'react-icons/fa';
import styled from 'styled-components';

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    setVisible(scrolled > 300);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);
    return () => window.removeEventListener('scroll', toggleVisible);
  }, []);

  return (
    <Button visible={visible}>
      <FaArrowCircleUp onClick={scrollToTop} />
    </Button>
  );
};

export default ScrollButton;

export const Heading = styled.h1`
  text-align: center;
  color: #800080;
`;

export const Content = styled.div`
  overflow-y: scroll;
  height: 2500px;
`;

export const Button = styled.div`
  position: fixed;
  right: 35px;
  bottom: 80px;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
  color: #800080;
  display: ${({ visible }) => (visible ? 'block' : 'none')};

  @media (max-width: 768px) {
    font-size: 2.5rem;
    bottom: 80px;
    right: 30px;
  }

  @media (max-width: 480px) {
    font-size: 2rem;
    bottom: 95px;
    right: 30px;
  }
`;