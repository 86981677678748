import React from 'react';
import styled, { keyframes } from 'styled-components';

// Helper function to generate multiple box shadows
const multipleBoxShadow = (n) => {
  let value = `${Math.random() * 2000}px ${Math.random() * 2000}px #FFF`; // Corrected template literal
  for (let i = 2; i <= n; i++) {
    value += `, ${Math.random() * 2000}px ${Math.random() * 2000}px #FFF`; // Corrected template literal
  }
  return value;
};

// Define the keyframes for the animation
const animStar = keyframes`
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
`;

// Define styled components
const Background = styled.div`
  height: 120vh; /* Ensure the background covers the full viewport height */
  width: 100vw;  /* Ensure the background covers the full viewport width */
  overflow: hidden;
  background: radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%);
  position: relative; /* Ensures absolute positioning of child elements */
  margin: 0; /* Remove any default margins */
  padding: 0; /* Remove any default padding */
`;

const Stars = styled.div`
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: ${multipleBoxShadow(700)};
  animation: ${animStar} 50s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: ${multipleBoxShadow(700)};
  }
`;

const Stars2 = styled(Stars)`
  width: 2px;
  height: 2px;
  box-shadow: ${multipleBoxShadow(200)};
  animation: ${animStar} 100s linear infinite;

  &:after {
    top: 2000px;
    width: 2px;
    height: 2px;
    box-shadow: ${multipleBoxShadow(200)};
  }
`;

const Stars3 = styled(Stars)`
  width: 3px;
  height: 3px;
  box-shadow: ${multipleBoxShadow(100)};
  animation: ${animStar} 150s linear infinite;

  &:after {
    top: 2000px;
    width: 3px;
    height: 3px;
    box-shadow: ${multipleBoxShadow(100)};
  }
`;

// const Title = styled.div`
//   position: absolute;
//   top: 50%;
//   left: 0;
//   right: 0;
//   color: #FFF;
//   text-align: center;
//   font-family: 'Lato', sans-serif;
//   font-weight: 300;
//   font-size: 50px;
//   letter-spacing: 10px;
//   margin-top: -60px;
//   padding-left: 10px;

//   span {
//     background: -webkit-linear-gradient(white, #38495a);
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: transparent;
//   }
// `;

// Define the AnimatedBackground component
const AnimatedBackground = () => {
  return (
    <Background>
      <Stars />
      <Stars2 />
      <Stars3 />
    </Background>
  );
};

export default AnimatedBackground;
