import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faClock, faChartLine, faBell, faFileAlt, faGlobe } from '@fortawesome/free-solid-svg-icons';
import '../styles2.css';
import bannerImage from '../../../assets/img/gif/cyber.gif';
import Background from '../../../assets/img/add/new_bg_ai.png';
const CyberThreatMonitoring = () => {
  return (
    <>
      <div className="banner-area">
      <img src={bannerImage} alt="Web Development Banner" className="banner-image" />

        <h2>Cyber Threat Monitoring</h2>
      </div>
      <Navbar />
   
      <section className='img' style={{ backgroundImage: `url(${Background})` }}>

      <div className="content"  >
        <h2>In an era where cyber threats are evolving at an unprecedented pace, having a robust cyber threat monitoring system is crucial for safeguarding your business.</h2>
        <p>Our comprehensive cyber threat monitoring services provide real-time detection, analysis, and response to potential security threats, ensuring your digital assets remain secure and your business operations uninterrupted.</p>

        <h3>Why Choose Our Cyber Threat Monitoring Services?</h3>
        <ul>
          <li><FontAwesomeIcon icon={faSearch} /> Proactive Threat Detection: Our advanced monitoring systems proactively detect threats before they can impact your business.</li>
          <li><FontAwesomeIcon icon={faClock} /> 24/7 Surveillance: With around-the-clock monitoring, our security experts are always on guard, identifying and responding to threats in real-time.</li>
          <li><FontAwesomeIcon icon={faChartLine} /> Comprehensive Threat Analysis: We provide in-depth analysis of detected threats, understanding their nature, origin, and potential impact.</li>
          <li><FontAwesomeIcon icon={faBell} /> Automated Alerts and Responses: Our systems automatically alert and respond to potential threats, reducing response times.</li>
          <li><FontAwesomeIcon icon={faFileAlt} /> Incident Reporting and Forensics: Detailed incident reports and forensic analysis help you understand the root cause of security breaches.</li>
          <li><FontAwesomeIcon icon={faGlobe} /> Customizable Monitoring Solutions: We tailor our monitoring solutions to fit the unique needs of your business.</li>
          <li><FontAwesomeIcon icon={faChartLine} /> Threat Intelligence Integration: By integrating global threat intelligence, we stay ahead of emerging threats.</li>
        </ul>

        <h3>Benefits of Our Cyber Threat Monitoring Services</h3>
        <ul>
          <li>Enhanced Security Posture: Stay one step ahead of cyber threats with proactive detection and response.</li>
          <li>Minimized Downtime: Rapid threat mitigation ensures minimal disruption to your business operations.</li>
          <li>Data Protection: Safeguard sensitive information from unauthorized access and breaches.</li>
          <li>Compliance Assurance: Ensure adherence to industry regulations and standards with comprehensive monitoring and reporting.</li>
          <li>Peace of Mind: Focus on your business growth knowing your digital assets are protected by top-tier security solutions.</li>
        </ul>

        <p>Protect your business from the ever-growing landscape of cyber threats. Contact us today to learn more about how our customized cyber threat monitoring services can enhance your security posture and provide the peace of mind you need to focus on innovation and growth.</p>
      </div>
      </section>

      <Footer />
    </>
  );
};

export default CyberThreatMonitoring;
