import React from 'react';

import TopNavbar from '../pages/Navbar';
import Footer from '../pages/Footer2'
import '../pages/style.css'


const Refund = () => {
    return (
        <>
        <div className="banner-area1" id="refund">
        <h2> Refund Policy</h2>
      </div>
        <TopNavbar />
        <section id="header" className='image'>
            {/* <h1 className="title">Refund Policy</h1> */}<br></br>
            <div className="container center">
                <div className="row mt-5 justify-center">
                    <div className="col-12">
                        <div className="content">
                            
                                <h2>Refund Policy for ES EthicSecur SofTec PVT LTD</h2>
                                <p><strong>Effective Year:</strong> 2023</p>
                        
                                <ol>
                                    <li>
                                        <h3>Introduction</h3>
                                        <p>At ES EthicSecur SofTec PVT LTD, we are committed to ensuring customer satisfaction with our services. This Refund Policy outlines the terms and conditions under which we offer refunds for our training programs and consultancy services.</p>
                                    </li>
                        
                                    <li>
                                        <h3>Eligibility for Refunds</h3>
                                        <h4>Training Programs:</h4>
                                        <ul>
                                            <li><strong>Cancellation:</strong> If you wish to cancel your enrollment in a training program, you must notify us at least 5 days before the start date of the program to be eligible for a full refund.</li>
                                            <li><strong>Partial Attendance:</strong> If you attend part of the training program and then decide to withdraw, you may be eligible for a partial refund based on the proportion of the program attended and the reason for withdrawal, at our discretion.</li>
                                            <li><strong>Non-Attendance:</strong> No refunds will be issued for non-attendance without prior notice and a valid reason.</li>
                                        </ul>
                        
                                        <h4>Consultancy Services:</h4>
                                        <ul>
                                            <li><strong>Service Cancellation:</strong> If you cancel a consultancy service before it has commenced, you are eligible for a full refund.</li>
                                            <li><strong>Service in Progress:</strong> If you cancel a consultancy service after it has commenced, you may be eligible for a partial refund based on the proportion of the service completed, at our discretion.</li>
                                        </ul>
                                    </li>
                        
                                    <li>
                                        <h3>Process for Requesting a Refund</h3>
                                        <ol>
                                            <li><strong>Contact Us:</strong> Email us at <a href="mailto:info@ethicsecur.com">info@ethicsecur.com</a> or call us at <a href="tel:+917550028487">7550028487</a> to notify us of your request for a refund. Please include your full name, contact information, details of the service or training program, and the reason for the refund request.</li>
                                            <li><strong>Review:</strong> Upon receiving your request, we will review the details and determine your eligibility for a refund. We may contact you for additional information if necessary.</li>
                                            <li><strong>Decision:</strong> We will notify you of our decision within 5-7 business days. If your request is approved, we will process the refund within 15 business days from the date of approval.</li>
                                        </ol>
                                    </li>
                        
                                    <li>
                                        <h3>Refund Method</h3>
                                        <p>Refunds will be issued using the same method of payment used for the original transaction unless otherwise agreed upon. If the original payment method is not available, we will contact you to arrange an alternative method.</p>
                                    </li>
                        
                                    <li>
                                        <h3>Non-Refundable Items</h3>
                                        <ul>
                                            <li><strong>Digital Products:</strong> Fees for digital products or services, such as eBooks, online courses, or downloadable materials, are non-refundable once access has been granted.</li>
                                            <li><strong>Promotional Offers:</strong> Fees for services or training programs purchased at a discount or as part of a promotional offer are non-refundable.</li>
                                        </ul>
                                    </li>
                        
                                    <li>
                                        <h3>Changes to This Refund Policy</h3>
                                        <p>We reserve the right to modify this Refund Policy at any time. Any changes will be posted on our website, and we encourage you to review this policy periodically.</p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    
                </div>
            </div>
        </section>
        <Footer />
        </>
    );
};

export default Refund;