import React from "react";
import styled from "styled-components";
import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";

export default function FullButton({ title, action, border, link, scroll }) {
  return (
    <Wrapper
      as={scroll ? ScrollLink : link ? RouterLink : 'button'}
      to={scroll ? scroll : link}
      smooth={scroll ? true : undefined}
      offset={scroll ? -80 : undefined}
      duration={scroll ? 500 : undefined}
      className="animate pointer radius8"
      onClick={action ? () => action() : null}
      border={border}
    >
      {title}
    </Wrapper>
  );
}

const Wrapper = styled.button`
  border: 1px solid ${(props) => (props.border ? "#707070" : "#7620ff")};
  background-color: ${(props) => (props.border ? "transparent" : "#7620ff")};
  width: 100%;
  padding: 15px;
  outline: none;
  color: ${(props) => (props.border ? "#707070" : "#fff")};
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;

  :hover {
    background-color: ${(props) => (props.border ? "transparent" : "#580cd2")};
    border-color: #7620ff;
    color: ${(props) => (props.border ? "#7620ff" : "#fff")};
  }

  @media (max-width: 768px) {
    padding: 12px; /* Adjusted padding for smaller screens */
    font-size: 14px; /* Adjusted font size for smaller screens */
  }
`;
