import React from 'react';
import styled, { keyframes } from 'styled-components';
import image2 from "../../../assets/img/blogs_image/blog7.jpg"
// Keyframes for animation
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;


const slideIn = keyframes`
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

// Styled components
const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: auto;
  animation: ${fadeIn} 1s ease-in;
`;
const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 20px;
  animation: ${fadeIn} 1s ease-in;
`;

const Title = styled.h1`
  text-align: center;
  color: #7620ff;
  margin-bottom: 20px;
  animation: ${slideIn} 1s ease-out;
`;

const Section = styled.div`
  margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
  color: #7620ff;
  animation: ${fadeIn} 1.5s ease-in;
`;

const Paragraph = styled.p`
  color: #4a4a4a;
  line-height: 1.6;
  animation: ${fadeIn} 2s ease-in;
`;

const List = styled.ul`
  color: #4a4a4a;
  padding-left: 20px;
  animation: ${fadeIn} 2s ease-in;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  animation: ${fadeIn} 2.5s ease-in;
`;

const DataPrivacy = () => {
  return (
    <Container>
      <Image src={image2} alt="dataprivacy-2024" />
      <Title>Understanding Data Breaches and Hacks: Protecting Your Business in the Digital Age</Title>
      <Paragraph>
      In today’s interconnected world, data breaches and hacks are serious concerns for businesses 
of all sizes. With sensitive data being stored and transmitted digitally, the risk of unauthorized 
access and cyberattacks is higher than ever. Understanding these threats and implementing 
effective security measures is crucial for safeguarding your business and maintaining 
customer trust.
      </Paragraph>

      <Section>
        <SectionTitle>What is a Data Breach?</SectionTitle>
        <Paragraph>A data breach occurs when sensitive or confidential data is accessed without authorization. 
This includes personal information such as names, social security numbers, and credit card 
details. Breaches can result from hacking, phishing, malware, or insider threats.</Paragraph>
<SectionTitle>Notable Data Breaches and Hacks:</SectionTitle>
        <List>
          <ListItem>
            <strong>General Data Protection Regulation (GDPR)</strong>
            <List>
              <ListItem><strong> Equifax (2017):</strong>  Hackers exploited a vulnerability, affecting 147 million people by 
accessing personal information like social security numbers.
</ListItem>
              <ListItem><strong> Yahoo (2013-2014):</strong>  Two major breaches compromised over 3 billion accounts, 
              exposing names, email addresses, and security details
              </ListItem>
              <ListItem><strong>Marriott International (2018):</strong>  Hackers accessed guest information for 500 million 
              people, including passport numbers and travel details.
              </ListItem>
              <ListItem><strong>The Impact of Data Breaches:</strong>  Data breaches can lead to significant financial losses, 
              damage to your business's reputation, operational disruptions, and legal consequences.
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            <SectionTitle>Preventative Measures to Protect Your Business:</SectionTitle>
            <List>
              <ListItem><strong>Strong Password Policies:</strong>  Use complex passwords and consider multi-factor 
              authentication.</ListItem>
              <ListItem><strong>Regular Software Updates:</strong> Keep systems updated to patch vulnerabilities
              </ListItem>
              <ListItem><strong>Employee Training:</strong>  Educate staff on phishing and social engineering
              </ListItem>
              <ListItem><strong>Access Controls:</strong> Restrict access to sensitive information to essential personnel
              </ListItem>
              <ListItem><strong>Data Encryption:</strong>   Encrypt sensitive data in transit and at rest.
              </ListItem>
              <ListItem><strong>Regular Security Audits:</strong>  Identify and address potential weaknesses regularly.
              </ListItem>
              <ListItem><strong>Responding to a Data Breach: </strong>  If a breach occurs, act quickly to contain the damage, assess 
the impact, notify affected parties, and improve security measures to prevent future incidents.

              </ListItem>
              
            </List>
          </ListItem>
       
         
        </List>
      </Section>

    

      

      <Section>
        <SectionTitle>Conclusion</SectionTitle>
        <Paragraph>
        Data breaches and hacks are ever-present threats. By understanding the risks 
and implementing robust security measures, businesses can protect their sensitive information 
and maintain customer trust. Stay vigilant and proactive in your approach to cybersecurity to 
safeguard your business from potential threats
        </Paragraph>
      </Section>
    </Container>
  );
};

export default DataPrivacy;