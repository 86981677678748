import React from 'react';
import Navbar from '../pages/Navbar';
import Footer from '../pages/Footer2';
import '../pages/style.css';

const Terms = () => {
    return (
        <>
            <div className="banner-area1" id="terms">
                <h2>Terms of Services</h2>
            </div>
            <Navbar />
            <section id="header" className='image'>
                {/* <h1 className="title">Terms of Service</h1> */}<br></br>
                <div className="container center">
                    <div className="row mt-5 justify-center">
                        <div className="col-12">
                            <div className="content">
                                <h2>Terms of Service for ES EthicSecur SofTec PVT LTD</h2>
                                <ol>
                                    <li>
                                        <h3>Introduction</h3>
                                        <p>Welcome to ES EthicSecur SofTec PVT LTD. These terms and conditions outline the rules and regulations for the use of our services and products. By accessing our services, you accept these terms and conditions in full. Do not continue to use ES EthicSecur SofTec PVT LTD's services if you do not agree with all the terms and conditions stated on this page.</p>
                                    </li>
                                    <li>
                                        <h3>Definitions</h3>
                                        <ul>
                                            <li><strong>Company:</strong> Refers to ES EthicSecur SofTec PVT LTD.</li>
                                            <li><strong>Client:</strong> Refers to any individual or entity using the services provided by the Company.</li>
                                            <li><strong>Services:</strong> Refers to the range of services provided by the Company, including cybersecurity training, full stack development training.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <h3>Services</h3>
                                        <p>The Company offers various training programs. Details of each service, including duration, content, and fees, are provided on our website and promotional materials.</p>
                                    </li>
                                    <li>
                                        <h3>Use of Services</h3>
                                        <ul>
                                            <li>Clients must provide accurate and complete information when registering for our services.</li>
                                            <li>Clients must not use our services for any illegal or unauthorized purpose.</li>
                                            <li>The Company reserves the right to refuse service to anyone for any reason at any time.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <h3>Payment Terms</h3>
                                        <ul>
                                            <li>Payment for services must be made in full prior to the service's commencement, unless otherwise agreed upon.</li>
                                            <li>Payment methods accepted by the Company include bank transfer, credit card, and other methods specified on our website.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <h3>Cancellation and Refund Policy</h3>
                                        <ul>
                                            <li>No refund policy.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <h3>Intellectual Property</h3>
                                        <ul>
                                            <li>All materials provided during our services are the intellectual property of the Company and are protected by copyright laws.</li>
                                            <li>Clients are not permitted to reproduce, distribute, or create derivative works from these materials without prior written consent from the Company.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <h3>Confidentiality</h3>
                                        <ul>
                                            <li>The Company agrees to keep any confidential information shared by the Client private and secure.</li>
                                            <li>The Client agrees not to disclose any confidential information received during the service without the Company's prior written consent.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <h3>Limitation of Liability</h3>
                                        <ul>
                                            <li>The Company is not liable for any direct, indirect, incidental, or consequential damages arising from the use of our services.</li>
                                            <li>The Company’s total liability to the Client for any claim arising out of or relating to the services shall not exceed the amount paid by the Client to the Company for those services.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <h3>Governing Law</h3>
                                        <p>These terms and conditions are governed by and construed in accordance with the laws of India, and any disputes relating to these terms and conditions will be subject to the exclusive jurisdiction of the courts of India.</p>
                                    </li>
                                    <li>
                                        <h3>Changes to Terms and Conditions</h3>
                                        <p>The Company reserves the right to revise these terms and conditions at any time. Clients are expected to check this page regularly to take notice of any changes.</p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Terms;