import React from "react";
// Import ParallaxProvider from react-scroll-parallax
import { ParallaxProvider } from 'react-scroll-parallax'; 
// Import custom ParallaxBackground component
import ParallaxBackground from '../components/ParallaxBackground'; 

// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import About from '../components/Sections/About';
import Services from "../components/Sections/Services";
import TestimonialSlider from "../components/Elements/TestimonialSlider";
import Counting from "../components/Counting";
import Chatbot from "../components/pages/ChatBot";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer";

// Images
import headerBackgroundImage from '../assets/img/add/ai_img.png';
import AboutBackgroundImage from "../assets/img/add/new_bg_ai.png";

export default function Landing() {
  return (
    <>
      <ParallaxProvider>
        <TopNavbar />
        <ParallaxBackground
          image={headerBackgroundImage}
          imageWidth="100%" 
          imageHeight="auto"
        >
          <Header />
        </ParallaxBackground>
        <About />
        <ParallaxBackground
          image={AboutBackgroundImage}
          imageWidth="100%" 
          imageHeight="900px"
        >
          <Services />
        </ParallaxBackground>
        <TestimonialSlider />
        <ParallaxBackground
          image={AboutBackgroundImage}
          imageWidth="100%" 
          imageHeight="900px"
        >
          <Contact />
        </ParallaxBackground>
        <Counting />
     
        <Chatbot />
        <Footer />
      </ParallaxProvider>
    </>
  );
}
