import React, { useState } from 'react';
import './ProductModal.css'; 
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from '@mui/material';

const stripHtmlTags = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

const ProductModal = ({ open, onClose, onAddProduct }) => {
  const [itemName, setItemName] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [unitPrice, setUnitPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [hsn, setHsn] = useState(0);
  const [description, setDescription] = useState('');

  const modules = {
    toolbar: [
      [{ 'bold': true }, { 'italic': true }, { 'underline': true }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    ],
  };

  const handleSubmit = () => {
    // Calculate the total price before discount
    const totalPrice = unitPrice * quantity;

    // Calculate the discount amount (percentage-based)
    const discountAmount = (discount / 100) * totalPrice;

    // Calculate the net amount after applying the discount
    const netAmount = totalPrice - discountAmount;

    const plainDescription = stripHtmlTags(description);
    const newProduct = {
      itemName,
      quantity,
      unitPrice,
      discount,
      netAmount,
      hsn,
      description: plainDescription,
    };

    if (onAddProduct) {
      onAddProduct(newProduct); // Check if onAddProduct is defined
    }
    onClose(); // Close the modal
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add Item</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <TextField
            margin="normal"
            fullWidth
            label="Item Name"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
            placeholder="Enter Item Name"
          />
          <TextField
            margin="normal"
            fullWidth
            type="number"
            label="Quantity"
            value={quantity}
            onChange={(e) => setQuantity(parseInt(e.target.value))}
          />
          <TextField
            margin="normal"
            fullWidth
            type="number"
            label="Unit Price"
            value={unitPrice}
            onChange={(e) => setUnitPrice(parseFloat(e.target.value))}
          />
          <TextField
            margin="normal"
            fullWidth
            type="number"
            label="Discount (Total Amount)"
            value={discount}
            onChange={(e) => setDiscount(parseFloat(e.target.value))}
          />
          <TextField
            margin="normal"
            fullWidth
            type="number"
            label="HSN/SAC"
            value={hsn}
            onChange={(e) => setHsn(parseFloat(e.target.value))}
          />
          <label htmlFor="description" className="editor-label">Description</label>
          <div className="description-editor">
            <ReactQuill
              value={description}
              onChange={(content) => setDescription(content)}
              modules={modules}
              placeholder="Add product description here..."
            />
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        <Button onClick={handleSubmit} color="primary">Add Item</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductModal;
