import React from 'react';
import styled, { keyframes } from 'styled-components';
import image2 from "../../../assets/img/blogs_image/dataprivacy.jpg"

// Keyframes for animation
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;


const slideIn = keyframes`
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

// Styled components
const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: auto;
  animation: ${fadeIn} 1s ease-in;
`;
const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 20px;
  animation: ${fadeIn} 1s ease-in;
`;

const Title = styled.h1`
  text-align: center;
  color: #7620ff;
  margin-bottom: 20px;
  animation: ${slideIn} 1s ease-out;
`;

const Section = styled.div`
  margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
  color: #7620ff;
  animation: ${fadeIn} 1.5s ease-in;
`;

const Paragraph = styled.p`
  color: #4a4a4a;
  line-height: 1.6;
  animation: ${fadeIn} 2s ease-in;
`;

const List = styled.ul`
  color: #4a4a4a;
  padding-left: 20px;
  animation: ${fadeIn} 2s ease-in;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  animation: ${fadeIn} 2.5s ease-in;
`;

const DataPrivacy = () => {
  return (
    <Container>
      <Image src={image2} alt="dataprivacy-2024" />
      <Title>Data Privacy Regulations and Their Impact on Software Development</Title>
      <Paragraph>
        In today’s digital age, data privacy has become a paramount concern for individuals, businesses, and governments alike. 
        As a result, stringent data privacy regulations have been enacted worldwide to protect personal information. 
        For software developers, understanding and adhering to these regulations is crucial. This blog will delve into major 
        data privacy regulations and their impact on software development.
      </Paragraph>

      <Section>
        <SectionTitle>Understanding Major Data Privacy Regulations</SectionTitle>
        <List>
          <ListItem>
            <strong>General Data Protection Regulation (GDPR)</strong>
            <List>
              <ListItem><strong>Jurisdiction:</strong> European Union (EU)</ListItem>
              <ListItem><strong>Key Points:</strong> GDPR emphasizes transparency, data minimization, and user consent. 
                It grants individuals rights over their personal data, including access, rectification, and erasure.
              </ListItem>
              <ListItem><strong>Impact on Development:</strong> Developers must implement features that allow users to easily manage their data. 
                Privacy by design and default must be integrated into the software development lifecycle (SDLC).
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            <strong>California Consumer Privacy Act (CCPA)</strong>
            <List>
              <ListItem><strong>Jurisdiction:</strong> California, USA</ListItem>
              <ListItem><strong>Key Points:</strong> CCPA provides California residents with rights similar to those under GDPR, 
                such as the right to know what personal data is being collected and the right to delete that data.
              </ListItem>
              <ListItem><strong>Impact on Development:</strong> Software must include mechanisms to handle data access and deletion requests. 
                Transparency in data collection and processing practices is essential.
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            <strong>Health Insurance Portability and Accountability Act (HIPAA)</strong>
            <List>
              <ListItem><strong>Jurisdiction:</strong> USA</ListItem>
              <ListItem><strong>Key Points:</strong> HIPAA focuses on the protection of health information. It mandates standards for the storage, 
                handling, and transmission of health data.
              </ListItem>
              <ListItem><strong>Impact on Development:</strong> Developers working with health data must ensure robust security measures, 
                including encryption and access controls, are in place.
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            <strong>Personal Data Protection Bill (PDPB)</strong>
            <List>
              <ListItem><strong>Jurisdiction:</strong> India</ListItem>
              <ListItem><strong>Key Points:</strong> PDPB aims to protect personal data and ensure the privacy of individuals. 
                It outlines principles for data processing, rights of individuals, and obligations of data fiduciaries.
              </ListItem>
              <ListItem><strong>Impact on Development:</strong> Similar to GDPR, developers need to integrate privacy features and ensure compliance with data processing principles.
              </ListItem>
            </List>
          </ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle>Impact on Software Development</SectionTitle>
        <List>
          <ListItem>
            <strong>Privacy by Design:</strong> Developers must incorporate privacy from the outset of the design process. 
            This involves considering data protection principles at every stage of development and ensuring that privacy is a default setting.
          </ListItem>
          <ListItem>
            <strong>Data Minimization:</strong> Collect only the data that is necessary for the functionality of the software. 
            Excessive data collection not only increases the risk of non-compliance but also poses security risks.
          </ListItem>
          <ListItem>
            <strong>User Consent and Control:</strong> Ensure that users are informed about data collection practices and obtain their explicit consent. 
            Provide users with control over their data, including options to access, modify, and delete their personal information.
          </ListItem>
          <ListItem>
            <strong>Security Measures:</strong> Implement robust security measures to protect personal data from breaches. 
            This includes encryption, secure authentication, and regular security audits.
          </ListItem>
          <ListItem>
            <strong>Transparency and Accountability:</strong> Maintain transparency in data handling practices and be accountable for data protection. 
            Document data processing activities and conduct regular compliance checks.
          </ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle>Challenges and Solutions</SectionTitle>
        <List>
          <ListItem>
            <strong>Keeping Up with Regulations:</strong> Regulations are continuously evolving. Staying updated with changes and ensuring ongoing compliance can be challenging.
            <br/><strong>Solution:</strong> Regularly review regulatory updates and consider employing a dedicated compliance team or consultant.
          </ListItem>
          <ListItem>
            <strong>Balancing Functionality and Privacy:</strong> Sometimes, privacy features may conflict with desired software functionalities.
            <br/><strong>Solution:</strong> Strive for a balance by integrating essential privacy features without compromising on core functionalities. Engage in user-centric design practices.
          </ListItem>
          <ListItem>
            <strong>Resource Allocation:</strong> Implementing compliance measures can be resource-intensive, particularly for small businesses.
            <br/><strong>Solution:</strong> Leverage open-source tools and frameworks designed to facilitate compliance. Invest in staff training and awareness programs.
          </ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle>Conclusion</SectionTitle>
        <Paragraph>
          Adhering to data privacy regulations is not just a legal obligation but also a critical aspect of building user trust and ensuring the security of personal information. 
          By integrating privacy considerations into the software development process, developers can create applications that are not only compliant but also secure and user-friendly. 
          As regulations continue to evolve, staying informed and proactive in privacy practices will be key to successful software development.
        </Paragraph>
      </Section>
    </Container>
  );
};

export default DataPrivacy;