import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import { FiPlusCircle } from 'react-icons/fi';
import ProductModal from './popup/ProductModel.jsx';
import { useNavigate } from 'react-router-dom';

const CreateInvoice = () => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [customerName, setCustomerName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [invoiceType, setInvoiceType] = useState('service');
  const [invoiceId, setInvoiceId] = useState('');
  const [gstNumber, setGstNumber] = useState('');
  const [address, setAddress] = useState('');

  // State for manual date entry
  const [invoiceDate, setInvoiceDate] = useState(new Date().toLocaleDateString());

  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  const generateInvoiceId = (type, counter) => {
    const prefix = 'IND';
    const typeCode = type === 'service' ? 'SER' : 'DEV';
    const monthCode = currentMonth.toString().padStart(2, '0');
    const counterCode = String(counter).padStart(3, '0'); // Pad counter with zeros
    return `${prefix}-${typeCode}-${monthCode}${counterCode}`;
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleAddProduct = (product) => {
    setProducts([...products, product]);
    handleCloseModal();
  };

  const getInvoiceCounter = () => {
    const storedInvoices = JSON.parse(localStorage.getItem('invoices') || '[]');

    // Filter invoices for the current month and invoice type
    const monthlyInvoices = storedInvoices.filter((invoice) => {
      const invoiceDate = new Date(invoice.date);
      return (
        invoiceDate.getMonth() + 1 === currentMonth &&
        invoiceDate.getFullYear() === currentYear &&
        invoice.id.includes(invoiceType === 'service' ? 'SER' : 'DEV')
      );
    });

    // Extract the last three digits of existing invoice IDs for the current month
    const usedCounters = monthlyInvoices.map((invoice) => {
      const idParts = invoice.id.split('-');
      return parseInt(idParts[3]); // Extract the last three digits (counter)
    });

    // Find the next available unique counter
    let newCounter = 1;
    while (usedCounters.includes(newCounter)) {
      newCounter += 1;
    }

    return newCounter; // Return the next available counter
  };

  useEffect(() => {
    const newCounter = getInvoiceCounter(); // Get new counter for current type
    const generatedId = generateInvoiceId(invoiceType, newCounter); // Generate new ID

    // Set default invoice ID to generated ID if it hasn't been set manually
    if (!invoiceId) {
      setInvoiceId(generatedId);
    }

    // Check if the invoice ID already exists in local storage
    const storedInvoices = JSON.parse(localStorage.getItem('invoices') || '[]');
    const exists = storedInvoices.some(invoice => invoice.id === invoiceId);

    // Prompt user if the invoice ID exists
    if (exists && invoiceId.startsWith('IND')) {
      const userConfirmed = window.confirm(
        `The invoice ID "${invoiceId}" already exists. Would you like to change it?`
      );
      if (userConfirmed) {
        setInvoiceId(''); // Clear the invoice ID for manual entry
      } else {
        setInvoiceId(invoiceId); // Keep the generated ID
      }
    }
  }, [invoiceType, invoiceId]); // Include all relevant dependencies

  const handleCreateInvoice = () => {
    const totalPrice = products.reduce((total, product) => total + product.netAmount, 0);
    const newInvoiceData = {
      id: invoiceId,
      client: customerName,
      phoneNumber,
      gstNumber,
      address,
      date: invoiceDate,
      price: totalPrice,
      products,
    };

    const storedInvoices = JSON.parse(localStorage.getItem('invoices') || '[]');
    const updatedInvoices = [...storedInvoices, newInvoiceData];
    localStorage.setItem('invoices', JSON.stringify(updatedInvoices));

    // Reset form after invoice creation
    setCustomerName('');
    setPhoneNumber('');
    setProducts([]);
    setInvoiceId(''); // Reset invoice ID
    alert('Invoice Created and Saved!');
    navigate('/invoices-table');
  };

  return (
    <Box className="invoice-container" sx={{ padding: 2 }}>
      <Box className="inputs">
        <TextField 
          label="Invoice ID" 
          variant="outlined" 
          fullWidth 
          value={invoiceId} 
          onChange={(e) => {
            const newInvoiceId = e.target.value;
            setInvoiceId(newInvoiceId);

            // Validation: Check if the new ID exists
            const storedInvoices = JSON.parse(localStorage.getItem('invoices') || '[]');
            const exists = storedInvoices.some(invoice => invoice.id === newInvoiceId);
            if (exists) {
              window.alert(`The invoice ID "${newInvoiceId}" already exists. Please enter a different ID.`);
            }
          }} 
          sx={{ marginBottom: 2 }} 
        />
        <TextField 
          label="Customer Name" 
          variant="outlined" 
          fullWidth 
          value={customerName} 
          onChange={(e) => setCustomerName(e.target.value)} 
          sx={{ marginBottom: 2 }} 
        />
        <TextField 
          label="Phone Number" 
          variant="outlined" 
          fullWidth 
          value={phoneNumber} 
          onChange={(e) => setPhoneNumber(e.target.value)} 
          sx={{ marginBottom: 2 }} 
        />
        <TextField
          label="GST Number"
          variant="outlined"
          fullWidth
          value={gstNumber}
          onChange={(e) => setGstNumber(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          label="Customer Address"
          variant="outlined"
          fullWidth
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        <TextField 
          label="Invoice Date" 
          variant="outlined" 
          fullWidth 
          value={invoiceDate} 
          onChange={(e) => setInvoiceDate(e.target.value)} 
          sx={{ marginBottom: 2 }} 
        />
        
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel id="invoice-type-label">Invoice Type</InputLabel>
          <Select 
            labelId="invoice-type-label" 
            value={invoiceType} 
            onChange={(e) => {
              setInvoiceType(e.target.value);
              setInvoiceId(''); // Clear the ID when changing types
            }}
          >
            <MenuItem value="service">Service</MenuItem>
            <MenuItem value="product">Product</MenuItem>
          </Select>
        </FormControl>
        <Button 
          variant="contained" 
          startIcon={<FiPlusCircle />} 
          onClick={handleOpenModal}
        >
          Add Product
        </Button>
      </Box>

      <ProductModal 
        open={isModalOpen} 
        onClose={handleCloseModal} 
        onAddProduct={handleAddProduct} 
      />
      
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Item Name</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Unit Price</TableCell>
              <TableCell>Discount in %</TableCell>
              <TableCell>Net Amount</TableCell>
              <TableCell>HSN/SAC</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product, index) => (
              <TableRow key={index}>
                <TableCell>{product.itemName}</TableCell>
                <TableCell>{product.quantity}</TableCell>
                <TableCell>{product.unitPrice}</TableCell>
                <TableCell>{product.discount}</TableCell>
                <TableCell>{product.netAmount}</TableCell>
                <TableCell>{product.hsn}</TableCell>
                <TableCell>{product.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography 
        variant="h6" 
        sx={{ marginTop: 2 }}
      >
        Total Amount: {products.reduce((total, product) => total + product.netAmount, 0)}
      </Typography>

      <Button 
        variant="contained" 
        onClick={handleCreateInvoice} 
        sx={{ marginTop: 2 }}
      >
        Create Invoice
      </Button>
    </Box>
  );
};

export default CreateInvoice;
