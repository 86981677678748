// src/Blog.js
import React from 'react';

const Blog = ({ title, content, author, published_at }) => {
  return (
    <div>
      <h2>{title}</h2>
      <p>{content}</p>
      <p>By {author}</p>
      <p>{new Date(published_at).toLocaleDateString()}</p>
    </div>
  );
};

export default Blog;