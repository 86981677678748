import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faClock, faFileAlt, faShieldAlt as faShieldAltSolid, faEye, faLock, faSearch, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import '../styles2.css';
import bannerImage from '../../../assets/img/gif/gif3.gif';
import Background from '../../../assets/img/add/new_bg_ai.png';

const NetworkSecurityPage = () => {
    return (
        <>
            <div className="banner-area">
            <img src={bannerImage} alt="Web Development Banner" className="banner-image" />

                <h2>Network Security Solutions</h2>
            </div>
            <Navbar />
            <section className='img' style={{ backgroundImage: `url(${Background})` }}>
            <div className="content">
                <p>In today’s digital landscape, robust network security is paramount to safeguarding your business from a myriad of cyber threats. Our comprehensive network security solutions are designed to protect your sensitive data, ensure compliance with industry standards, and maintain the integrity of your IT infrastructure.</p>

                <h3>Why Choose Our Network Security Solutions?</h3>
                <ul>
                    <li><FontAwesomeIcon icon={faShieldAlt} /> Advanced Threat Protection: Employ cutting-edge technologies to detect and neutralize threats.</li>
                    <li><FontAwesomeIcon icon={faClock} /> Real-Time Monitoring: Continuous monitoring to identify and respond to security incidents promptly.</li>
                    <li><FontAwesomeIcon icon={faFileAlt} /> Customized Security Policies: Tailor security policies to meet your organization’s specific needs and compliance requirements.</li>
                    <li><FontAwesomeIcon icon={faShieldAltSolid} />Firewall and VPN Solutions: Implement robust solutions for secure remote access and protection against external threats.</li>
                    <li><FontAwesomeIcon icon={faEye} /> Intrusion Detection and Prevention Systems (IDPS): Detect and prevent unauthorized access and malicious activities.</li>
                    <li><FontAwesomeIcon icon={faLock} /> Data Encryption: Protect sensitive information with advanced encryption solutions.</li>
                    <li><FontAwesomeIcon icon={faSearch} /> Security Audits and Assessments: Regular audits to identify vulnerabilities and ensure effective security measures.</li>
                    <li><FontAwesomeIcon icon={faPhoneAlt} /> 24/7 Support and Incident Response: Dedicated support team available around the clock to address security incidents.</li>
                </ul>

                <h3>Benefits of Our Network Security Solutions</h3>
                <ul>
                    <li>Enhanced Data Protection: Safeguard critical data from unauthorized access and breaches.</li>
                    <li>Regulatory Compliance: Ensure compliance with industry regulations and standards.</li>
                    <li>Operational Continuity: Minimize downtime and maintain business continuity with proactive security measures.</li>
                    <li>Peace of Mind: Rest assured knowing your network is protected by state-of-the-art security solutions.</li>
                </ul>

                <p>Don’t leave your network security to chance. Contact us today to learn more about how our tailored network security solutions can protect your business and provide the peace of mind you need to focus on growth and innovation.</p>
            </div>
            </section>
            <Footer />
        </>
    );
};

export default NetworkSecurityPage;
