import React from "react";
import styled from "styled-components";

// Import your icons (assuming they are image paths)
import NetworkIcon from '../../assets/img/cyber-security.png';
import DataIcon from '../../assets/img/data-security.png';
import CyberIcon from '../../assets/img/hack.png';
import WebIcon from '../../assets/img/app.png';
import ProductionIcon from '../../assets/img/help-desk.png';
import MarketingIcon from '../../assets/img/bullhorn.png';

export default function ServiceBox({ icon, title, subtitle }) {
  let getIcon;

  // Assign the correct image path based on the icon type
  switch (icon) {
    case "network":
      getIcon = NetworkIcon;
      break;
    case "data":
      getIcon = DataIcon;
      break;
    case "cyber":
      getIcon = CyberIcon;
      break;
    case "marketing":
      getIcon = MarketingIcon;
      break;
    case "web":
      getIcon = WebIcon;
      break;
    case "production":
      getIcon = ProductionIcon;
      break;
    default:
      getIcon = NetworkIcon;
      break;
  }

  return (
    <Wrapper className="flex flexColumn">
      <IconStyle>
        <IconImage src={getIcon} alt={icon} />
      </IconStyle>
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const IconStyle = styled.div`
  margin-bottom: 10px;
  width: 80px; /* Adjust icon size */
  height: 80px; /* Adjust icon size */
`;

const IconImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain; /* Maintain aspect ratio */
`;

const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 20px 0;
  font-size: 1.5rem; /* Adjust font size */
`;

const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  font-size: 1rem; /* Adjust font size */
`;

