import React, { useState, useEffect } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { createGlobalStyle } from 'styled-components';

// Global styles
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Cake', sans;
    background-color: #f9f9f9;
  }

  .App {
    text-align: center;
    padding: 20px;
  }

  h1 {
    font-size: 2.5em;
    color: #000;
    margin-bottom: 10px;
  }

  .description {
    font-size: 1.2em;
    color: #666;
    margin-bottom: 20px;
  }

  .counters {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .counter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    width: 150px;
  }

  .counter-number {
    font-size: 2.5em;
    color: #000;
    margin-top: 10px;
    font-weight: bold;
  }

  .counter-label {
    font-size: 1em;
    color: #000;
    margin-top: 10px;
    font-weight: bold;
  }
`;

const Counter = ({ start, end, duration, color, label }) => {
  const [count, setCount] = useState(start);

  const { number } = useSpring({
    from: { number: start },
    to: { number: end },
    config: { duration: duration },
    onChange: ({ value }) => {
      const currentCount = Math.floor(value.number);
      setCount(currentCount);
    },
  });

  return (
    <div className="counter-container">
      <animated.div className="counter-number" style={{ color: color }}>
        {number.to((val) => Math.floor(val))}
      </animated.div>
      <div className="counter-label">{label}</div>
    </div>
  );
};

const App = () => {
  const [clients, setClients] = useState(200);
  const [projects, setProjects] = useState(500);
  const [support, setSupport] = useState(24); // 24 hours for 1 day
  const [tasks, setTasks] = useState(30);

  useEffect(() => {
    const interval = setInterval(() => {
      setClients((prev) => prev + 50); // Increment clients
      setProjects((prev) => prev + 75); // Increment projects
      setSupport((prev) => prev + 24); // Increment support hours daily
      setTasks((prev) => {
        const newTask = Math.floor(Math.random() * (40 - 30 + 1)) + 30;
        return prev + newTask;
      });
    }, 86400000); // Update every 24 hours (86400000 milliseconds)

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <GlobalStyle />
      <div className="App">
        <h1>Our Achievements</h1>
        <div className="counters">
          <Counter start={0} end={clients} duration={5000} color="#7620ff" label="Clients" />
          <Counter start={0} end={projects} duration={8000} color="#7620ff" label="Projects" />
          <Counter start={0} end={support} duration={6000} color="#7620ff" label="Vigilance hours" />
          <Counter start={0} end={tasks} duration={7000} color="#7620ff" label="Employees" />
        </div>
      </div>
    </>
  );
};

export default App;