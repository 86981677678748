import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode, faMobileAlt, faShoppingCart, faFileAlt, faRocket, faSearch, faTools, faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import '../styles2.css';
import bannerImage from '../../../assets/img/gif/Gif.gif';
import Background from '../../../assets/img/add/new_bg_ai.png';

const WebDevelopment = () => {
  return (
    <>
      <div className="banner-area">
             <img src={bannerImage} alt="Web Development Banner" className="banner-image" />
 
        <h2>Web Development Services</h2>
      </div>
      <Navbar />
      <section className='img' style={{ backgroundImage: `url(${Background})` }}>
      <div className="content">
        <h2>In today’s digital age, a powerful online presence is essential for business success.</h2>
        <p>Our web development services are designed to create dynamic, user-friendly, and visually appealing websites that drive engagement, improve user experience, and enhance your brand's online presence. Whether you need a simple website or a complex web application, our team of experienced developers is here to deliver tailored solutions that meet your specific needs.</p>

        <h3>Why Choose Our Web Development Services?</h3>
        <ul>
          <li><FontAwesomeIcon icon={faLaptopCode} /> Custom Website Development: Tailored websites that reflect your brand and meet unique business requirements.</li>
          <li><FontAwesomeIcon icon={faMobileAlt} /> Responsive Design: Websites that function flawlessly on all devices, enhancing user experience and SEO.</li>
          <li><FontAwesomeIcon icon={faShoppingCart} /> E-Commerce Solutions: Secure, scalable, and user-friendly online stores with full integration.</li>
          <li><FontAwesomeIcon icon={faFileAlt} /> Content Management Systems (CMS): Easy-to-manage content with platforms like WordPress, Joomla, and Drupal.</li>
          <li><FontAwesomeIcon icon={faRocket} /> Web Applications: Custom apps improving business operations and user satisfaction.</li>
          <li><FontAwesomeIcon icon={faSearch} /> SEO-Friendly Development: Optimized for search engines to boost visibility and traffic.</li>
          <li><FontAwesomeIcon icon={faTools} /> Maintenance and Support: Regular updates, security patches, and technical support.</li>
          <li><FontAwesomeIcon icon={faTachometerAlt} /> Performance Optimization: Faster, secure websites enhancing user experience and conversions.</li>
        </ul>

        <h3>Benefits of Our Web Development Services</h3>
        <ul>
          <li>Enhanced User Experience: Provide a seamless and enjoyable experience for your visitors.</li>
          <li>Improved SEO: Increase visibility and attract more organic traffic with SEO-friendly development.</li>
          <li>Scalability: Build a website that grows with your business and adapts to changing needs.</li>
          <li>Brand Consistency: Maintain a cohesive and professional online presence aligned with your brand.</li>
          <li>Increased Engagement: Drive more interactions and conversions with a user-centric design.</li>
        </ul>

        <p>Elevate your online presence with a website that not only looks great but performs exceptionally. Contact us today to learn more about how our custom web development services can help you achieve your business goals and stand out in the digital world.</p>
      </div>
      </section>
      <Footer />
    </>
  );
};

export default WebDevelopment;
