// components/ChatBotComponent.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';

// Define the theme for the ChatBot
const theme = {
    background: '#f5f5f5', // light grey background
    fontFamily: 'Helvetica Neue',
    headerBgColor: '#7620ff', // purple header background
    headerFontColor: '#fff', // white font color
    headerFontSize: '15px',
    botBubbleColor: '#7620ff', // purple bot bubble
    botFontColor: '#fff', // white font color for bot
    userBubbleColor: '#eae0f8', // very light purple user bubble
    userFontColor: '#4a4a4a', // dark grey font color for user
  };
  
  

const ChatBotComponent = () => {
  const navigate = useNavigate();

  const handleRedirect = (path) => {
    return () => {
      navigate(path);
      return 'moreHelp'; // Trigger the next step after redirect
    };
  };

  return (
    <ThemeProvider theme={theme}>
      <ChatBot
        steps={[
          {
            id: '1',
            message: 'Hi, How can I help you?',
            trigger: '2',
          },
          {
            id: '2',
            user: true,
            trigger: '3',
          },
          {
            id: '3',
            options: [
              { value: 'services', label: 'Our services', trigger: 'services' },
              { value: 'about', label: 'About us', trigger: handleRedirect('about') },
              { value: 'careers', label: 'Tell me about careers',trigger: handleRedirect('/careers')  },
              { value: 'contact', label: 'Contact us',trigger: handleRedirect('/') },
              { value: 'pricing', label: 'Pricing', trigger: handleRedirect('/pricing') },
            ],
          },
          {
            id: 'services',
            options: [
              { value: 'network', label: 'Network Security Solutions', trigger: handleRedirect('/network-security') },
              { value: 'web', label: 'Web Development', trigger: handleRedirect('/web-development') },
              { value: 'production', label: 'Production Support', trigger: handleRedirect('/production-support') },
              { value: 'marketing', label: 'Marketing Services', trigger: handleRedirect('/marketing-services') },
              { value: 'cyber', label: 'Cyber Threat Monitoring', trigger: handleRedirect('/cyber-threat-monitoring') },
              { value: 'data', label: 'Data Encryption Services', trigger: handleRedirect('/data-encryption-services') },
            ],
          },
          {
            id: 'pricing',
            message: 'Our pricing details are available on our website.',
            trigger: 'moreHelp',
          },
          {
            id: 'moreHelp',
            message: 'Is there anything else I can help you with?',
            trigger: '3',
          },
        ]}
        floating={true}
      />
    </ThemeProvider>
  );
};

export default ChatBotComponent;